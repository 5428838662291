import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb, Spin } from "antd";
import { Routes, Route, useParams, Link } from "react-router-dom";

function PageTitle({
  title = "",
  loading,
  description = "",
  right,
  breadcrumbItems,
}: any) {
  return (
    <div className="page-title-box">
      <div className="container-fluid">
        {loading ? (
          ""
        ) : (
          <div className="row align-items-center">
            {breadcrumbItems ? (
              <div className="col-md-12">
                <Breadcrumb>
                  <Breadcrumb.Item>Эхлэл</Breadcrumb.Item>
                  {breadcrumbItems.map((item: any, i: number) => (
                    <Breadcrumb.Item key={i}>
                      {item.link ? (
                        <Link to={item.link}>{item.title}</Link>
                      ) : (
                        item.title
                      )}
                    </Breadcrumb.Item>
                  ))}
                </Breadcrumb>
              </div>
            ) : null}
            <div className="col-md-8">
              <h4 className="page-title mb-1">{title}</h4>
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">{description}</li>
              </ol>
            </div>
            {right ? (
              <div className="col-md-4">
                <div className="float-right d-none d-md-block">
                  <div className="dropdown">{right}</div>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}

export default PageTitle;
