import { PageTitle } from "../../components";
import Menu from "../../components/VerticalMenu";

function Privacy() {
  return (
    <>
      <PageTitle title="Тавтай морилно уу" description="Үйлчилгээний гэрээ" />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <h6
                      style={{
                        width: "60%",
                        textAlign: "left",
                        marginLeft: "20%",
                        marginTop: "5%"
                      }}
                    >
                      Энэхүү үйлчилгээний нөхцлийн дагуу "ДИЖИТАЛ АРЧЕРИ" ХК-иас
                      үзүүлж буй харваач өөрийн болон тэмцээний мэдээлэл харах,
                      тэмцээнд бүртгүүлэх, тэмцээний зохион байгуулагчид
                      тэмцээнийг удирдах зэрэг бусад үйлчилгээг ашиглахад
                      тавигдах шаардлагууд тодорхойлогдоно. <br />
                      <br></br> Таны харъяалалтай багаас илгээсэн өгөгдлийн
                      дагуу таны мэдээлэл манай вебсайтэд
                      "https://app.mongolian-archery.mn/archers" бүртгэгдсэн
                      байх ба таны цол, амжилтыг үнэн зөв илгээхийг та болон
                      багийн ахлагч хариуцна. Энэхүү өгөгдөл дээр бид үндэслэн
                      тэмцээний зогсоолыг тооцоолох болно. <br />
                      <br></br>
                      Аппликешнд тэмцээнүүдийн жагсаалт харагдах ба "Удахгүй
                      болох", "Бүртгүүлсэн", "Хаагдсан" гэсэн 3 төлөвтэй байна.
                      <br />
                      {/* <br></br> Та "ДИЖИТАЛ АРЧЕРИ" ХК-иас таны бүртгүүлсэн гар
                      утсанд илгээсэн баталгаажуулах кодыг оруулан
                      баталгаажуулсан бол таныг уг үйлчилгээний нөхцлийг хүлээн
                      зөвшөөрсөнд тооцно. <br></br>
                      <br></br>Бүртгэл үүсгэх явцад таны бүртгэлд нэвтрэх нэг
                      удаагийн кодыг үүсгэх шаардлагатай бөгөөд уг кодыг таны
                      гар утасны дугаарт илгээнэ. Иймд та өөрийн гар утасны
                      нууцлалыг та өөрөө бүрэн хариуцах болно. Үйлчилгээнд
                      нэвтрэх кодын нууцлал алдагдсан, халдлагад өртсөн
                      тохиолдолд бидэнд даруй мэдэгдэх үүрэгтэй. <br></br> */}
                      <br></br>"ДИЖИТАЛ АРЧЕРИ" ХК нь танд мэдэгдэлгүйгээр апп
                      болон үйлчилгээг сайжруулах, засвар үйлчилгээг хийж болно.
                      Энэхүү засвар үйлчилгээ болон сайжруулалтыг хийхэд апп
                      болон үйлчилгээний үйл ажиллагаанд тасалдал үүсэх нөхцөлд
                      энэ талаар урьдчилан мэдэгдэнэ. “Нэвтрэх нэр” хэсэгт
                      регистрийн дугаарийг бичих бөгөөд үсгийг криллээр, томоор
                      бичнэ үү. “Нууц үг” хэсэгт регистрийн сүүлийн 8 оронтой
                      тоог бичнэ үү.
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Privacy;
