import { Routes, Route } from "react-router-dom";
import { PrivateRoute } from "./components";
import {
  Login,
  Referee,
  Referees,
  Home,
  Competitions,
  Teams,
  Archers,
  Competition,
  Team,
  Archer,
  Ranks,
  Rank,
  News,
  NewsDetail,
  AddNews,
  Leaders,
  Leader,
  Ranking,
  Erembe,
  Privacy
} from "./pages";

function App() {
  return (
    <Routes>
      <Route key="login" path="login" element={<Login />} />
      <Route key="privacy" path="privacy-policy" element={<Privacy />} />

      <Route
        key="home"
        path="/"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />

      <Route
        key="addNews"
        path="add-news"
        element={
          <PrivateRoute>
            <AddNews />
          </PrivateRoute>
        }
      />
      <Route
        key="news"
        path="news"
        element={
          <PrivateRoute>
            <News />
          </PrivateRoute>
        }
      />

      <Route
        key="newsDetail"
        path="news/:newsId"
        element={
          <PrivateRoute>
            <NewsDetail />
          </PrivateRoute>
        }
      />

      <Route
        key="ranks"
        path="ranks"
        element={
          <PrivateRoute>
            <Ranks />
          </PrivateRoute>
        }
      />

      <Route
        key="rank"
        path="ranks/:rankId"
        element={
          <PrivateRoute>
            <Rank />
          </PrivateRoute>
        }
      />

      <Route
        key="teams"
        path="teams"
        element={
          <PrivateRoute>
            <Teams />
          </PrivateRoute>
        }
      />

      <Route
        key="team"
        path="teams/:teamId"
        element={
          <PrivateRoute>
            <Team />
          </PrivateRoute>
        }
      />

      <Route
        key="competitions"
        path="competitions"
        element={
          <PrivateRoute>
            <Competitions />
          </PrivateRoute>
        }
      />

      <Route
        key="competition"
        path="competitions/:competitionId"
        element={
          <PrivateRoute>
            <Competition />
          </PrivateRoute>
        }
      />

      <Route
        key="archers"
        path="archers"
        element={
          <PrivateRoute>
            <Archers />
          </PrivateRoute>
        }
      />

      <Route
        key="archer"
        path="archers/:archerId"
        element={
          <PrivateRoute>
            <Archer />
          </PrivateRoute>
        }
      />

      <Route
        key="referees"
        path="referees"
        element={
          <PrivateRoute>
            <Referees />
          </PrivateRoute>
        }
      />

      <Route
        key="referee"
        path="referees/:refereeId"
        element={
          <PrivateRoute>
            <Referee />
          </PrivateRoute>
        }
      />
      <Route
        key="leaders"
        path="leaders"
        element={
          <PrivateRoute>
            <Leaders />
          </PrivateRoute>
        }
      />

      <Route
        key="leader"
        path="leaders/:leaderId"
        element={
          <PrivateRoute>
            <Leader />
          </PrivateRoute>
        }
      />
      <Route
        key="rankings"
        path="rankings"
        element={
          <PrivateRoute>
            <Ranking />
          </PrivateRoute>
        }
      />
      <Route
        key="erembe"
        path="erembe"
        element={
          <PrivateRoute>
            <Erembe />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default App;
