import axios from "axios";
import { notification } from "antd";
import { cookies } from "./cookies";

export const baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_CF_DEV
    : process.env.REACT_APP_CF_PROD;

export const API = axios.create({
  baseURL: baseURL,
  withCredentials: false,
  timeout: 300000,
});

API.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error) {
      if (error.response !== undefined) {
        console.log(error.response);
        if (
          401 === error.response.status &&
          !error.request.responseURL.endsWith("/login")
        ) {
          //   window.location = "/login";
          console.log(error);
        } else {
          return error.response;
        }
      } else {
        console.log(error.response);
        // window.location = "/login";
        return Promise.reject(error);
      }
    }
  }
);

API.interceptors.request.use(
  (config) => {
    let token = cookies.get("Token") || "";
    let auth = token
      ? {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        }
      : { "Access-Control-Allow-Origin": "*" };
    if (config.headers && token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else if (!config.headers) {
      config.headers = auth;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
