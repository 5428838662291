import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import Zurhai from "./Zurhai";
import "./board.scss";

const EmtpyCard = () => {
  return (
    <td>
      <tr>
        <td colSpan={3} className="border-btm"></td>
      </tr>
      <tr>
        <td colSpan={3} className="border-btm"></td>
      </tr>
      <tr>
        <td colSpan={3} className="border-btm name-team"></td>
      </tr>
      <tr>
        <td colSpan={3} className="border-btm name-team"></td>
      </tr>
      <tr>
        <td className="border-none onoo-cell"></td>
        <td className="border-none rotate-line onoo-cell"></td>
        <td className="border-none onoo-cell"></td>
      </tr>
      <tr>
        <td className="border-btm"></td>
        <td></td>
        <td className="border-btm"></td>
      </tr>
      <tr className="backgroundcolorzuraas">
        <td className="border-none onoo-cell"></td>
        <td className="border-none rotate-line onoo-cell"></td>
        <td className="border-none onoo-cell"></td>
      </tr>
      <tr>
        <td className="border-btm"></td>
        <td></td>
        <td className="border-btm"></td>
      </tr>
      <tr>
        <td colSpan={3} className="border-none"></td>
      </tr>
    </td>
  );
};

export default EmtpyCard;
