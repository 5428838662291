import React, { useState, useEffect } from "react";
import { PageTitle } from "../../components";
import {
  Skeleton,
  Table,
  Space,
  Button,
  Form,
  Input,
  Select,
  Modal,
  Tabs,
  message,
  DatePicker,
  Radio
} from "antd";
import { db } from "../../firebase";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
  updateDoc
} from "firebase/firestore";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Routes, Route, useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import type { DatePickerProps } from "antd";

dayjs.extend(customParseFormat);
const ranksRef = collection(db, "ranks");

const { Option } = Select;
const { TabPane } = Tabs;

const dateFormat = "YYYY-MM-DD";

interface DataType {
  key?: string;
  lastName?: string;
  firstName?: string;
  teamId?: string;
}

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 }
};

function Archer() {
  const [form] = Form.useForm();
  const [searchTeacher] = Form.useForm();
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const ranks = useSelector((state: any) => state.content.ranks);
  const teams = useSelector((state: any) => state.content.teams);

  let { archerId } = useParams();

  useEffect(() => {
    if (archerId) {
      const unsub = onSnapshot(doc(db, "users", archerId), (doc: any) => {
        const docData = doc.data();
        setData(docData);

        form.setFieldsValue({
          ...docData,
          birthDate: docData.birthDate
            ? dayjs(docData.birthDate, dateFormat)
            : null,
          rateDate: docData.rateDate
            ? dayjs(docData.rateDate, dateFormat)
            : null,
          rateDate2: docData.rateDate2
            ? dayjs(docData.rateDate2, dateFormat)
            : null
        });
        setLoading(false);
      });

      return unsub;
    }
  }, [archerId]);

  const onFinish = () => {
    setConfirmLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        if (archerId) {
          await updateDoc(doc(db, "users", archerId), {
            ...values,
            birthDate: dayjs(values.birthDate).format(),
            rateDate: dayjs(values.rateDate).format(),
            rateDate2: dayjs(values.rateDate2).format()
          });

          if (Object.hasOwn(teams, values.teamId)) {
            try {
              await deleteDoc(doc(db, "users", archerId, "team", data.teamId));
            } catch (error) {
              console.error(error);
            }

            const selectedTeam = teams[values.teamId];

            await setDoc(
              doc(db, "users", archerId, "team", values.teamId),
              selectedTeam
            );
          }

          if (data?.rankId !== values.rankId) {
            try {
              await deleteDoc(doc(db, "users", archerId, "rank", data.rankId));
            } catch (error) {
              console.error(error);
            }

            const selectedRank = ranks[values.rankId];

            await setDoc(
              doc(db, "users", archerId, "rank", values.rankId),
              selectedRank
            );
          }

          setConfirmLoading(false);
          message.success("Амжилттай.");
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      {data ? (
        <PageTitle
          loading={loading}
          title={`${data?.lastName} ${data?.firstName}`}
          description={"Харваачийн профайл"}
          right={null}
          breadcrumbItems={[
            { link: "/archers", title: "Харваачид" },
            { title: `${data?.lastName} ${data?.firstName}` }
          ]}
        />
      ) : (
        ""
      )}

      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <Skeleton loading={loading} active>
                    <Form
                      {...formItemLayout}
                      form={form}
                      onFinish={() => onFinish()}
                    >
                      {/* <Form.Item
                        label="Хот/Аймаг"
                        name="cityProvince"
                        rules={[
                          { required: true, message: "Хот/Аймаг сонгоно уу!" },
                        ]}
                      >
                        <Select placeholder="Хот/Аймаг" allowClear>
                          <Option value="ulaanbaatar">Улаанбаатар</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Дүүрэг/Сум"
                        name="districtTown"
                        rules={[
                          { required: true, message: "Дүүрэг/Сум сонгоно уу!" },
                        ]}
                      >
                        <Select placeholder="Дүүрэг/Сум" allowClear>
                          <Option value="jargaltkhaan">Жаргалт хаан</Option>
                        </Select>
                      </Form.Item> */}
                      <Form.Item
                        label="Имэйл хаяг"
                        name="email"
                        rules={[
                          { required: true, message: "Имэйл хаяг оруулна уу!" }
                        ]}
                      >
                        <Input readOnly />
                      </Form.Item>
                      <Form.Item
                        label="Утасны дугаар"
                        name="phoneNumber"
                        rules={[
                          {
                            required: true,
                            message: "Утасны дугаар оруулна уу!"
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Овог"
                        name="lastName"
                        rules={[
                          { required: true, message: "Овог оруулна уу!" }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Нэр"
                        name="firstName"
                        rules={[{ required: true, message: "Нэр оруулна уу!" }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item label="Хүйс" name="gender">
                        <Radio.Group value="male">
                          <Radio.Button value="male">Эрэгтэй</Radio.Button>
                          <Radio.Button value="female">Эмэгтэй</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        label="Төрсөн огноо"
                        name="birthDate"
                        rules={[
                          {
                            required: false,
                            message: "Төрсөн огноо сонгоно уу!"
                          }
                        ]}
                      >
                        <DatePicker />
                      </Form.Item>
                      <Form.Item
                        label="Регистрийн дугаар"
                        name="ssn"
                        rules={[
                          {
                            required: true,
                            message: "Регистрийн дугаар оруулна уу!"
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Баг"
                        name="teamId"
                        rules={[{ required: true, message: "Баг сонгоно уу!" }]}
                      >
                        <Select placeholder="Баг" allowClear>
                          {Object.values(teams)
                            .sort((a: any, b: any) =>
                              a.name > b.name ? 1 : -1
                            )
                            .map((team: any) => (
                              <Option key={team.id} value={team.id}>
                                {team.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Улсын цол"
                        name="rankId"
                        rules={[{ required: true, message: "Цол сонгоно уу!" }]}
                      >
                        <Select placeholder="Улсын цол" allowClear>
                          {Object.values(ranks)
                            .filter((rank: any) => !rank.isRank)
                            .sort((a: any, b: any) =>
                              parseInt(a.order) > parseInt(b.order) ? 1 : -1
                            )
                            .map((rank: any) => (
                              <Option key={rank.id} value={rank.id}>
                                {rank.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Улсын цол авсан огноо"
                        name="rateDate"
                        rules={[
                          {
                            required: true,
                            message: "Улсын цол авсан огноо сонгоно уу!"
                          }
                        ]}
                      >
                        <DatePicker />
                      </Form.Item>
                      <Form.Item
                        label="Спортын зэрэг"
                        name="rate2"
                        rules={[
                          {
                            required: false,
                            message: "Спортын зэрэг сонгоно уу!"
                          }
                        ]}
                      >
                        <Select placeholder="Спортын зэрэг" allowClear>
                          {Object.values(ranks)
                            .filter((rank: any) => rank.isRank)
                            .sort((a: any, b: any) =>
                              parseInt(a.order) > parseInt(b.order) ? 1 : -1
                            )
                            .map((rank: any) => (
                              <Option key={rank.id} value={rank.id}>
                                {rank.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Спортын зэрэг авсан огноо"
                        name="rateDate2"
                        rules={[
                          {
                            required: true,
                            message: "Спортын зэрэг авсан огноо сонгоно уу!"
                          }
                        ]}
                      >
                        <DatePicker />
                      </Form.Item>
                      <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={confirmLoading}
                        >
                          Хадгалах
                        </Button>
                      </Form.Item>
                    </Form>
                  </Skeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Archer;
