import React, { useState, useEffect } from "react";
import { PageTitle } from "../../components";
import {
  Table,
  Space,
  Button,
  Form,
  Input,
  Select,
  Modal,
  Tabs,
  message,
  Skeleton,
  Popconfirm
} from "antd";
import { db } from "../../firebase";
import {
  collection,
  doc,
  onSnapshot,
  getDocs,
  query,
  where,
  updateDoc,
  collectionGroup
} from "firebase/firestore";
import dayjs from "dayjs";
import { Routes, Route, useParams, Link } from "react-router-dom";
import type { ColumnsType } from "antd/lib/table";
import { ArcheryProp } from "../../_helpers/type";

const { Option } = Select;
const { TabPane } = Tabs;

interface DataType {
  key?: string;
  name?: string;
}

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 }
};

function Team() {
  const [form] = Form.useForm();

  const [data, setData] = useState<DataType>({});
  const [archers, setArchers] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [ranks, setRanks] = useState<any>([]);

  let { teamId } = useParams();
  const users = query(
    collectionGroup(db, "users"),
    where("teamId", "==", teamId)
  );

  const columns: ColumnsType<ArcheryProp> = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => <>{index + 1}</>
    },
    {
      title: "Овог",
      dataIndex: "lastName",
      key: "lastName"
    },
    {
      title: "Нэр",
      dataIndex: "firstName",
      key: "firstName"
    },
    {
      title: "Цол",
      dataIndex: "rankId",
      key: "rankId",
      render: (value, record) => {
        const currentRank = ranks.find(
          (item: { id: any }) => item.id === value
        );

        return currentRank ? (
          currentRank.name
        ) : (
          <span style={{ color: "red" }}>Цол сонгоогүй</span>
        );
      }
    },
    {
      title: "Имэйл хаяг",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Үйлдэл",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/archers/${record.id}`} key={record.id}>
            Засах
          </Link>
        </Space>
      )
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "ranks"));
      const fetchedData: any = [];
      querySnapshot.forEach((doc) => {
        fetchedData.push({
          id: doc.id,
          ...doc.data()
        });
      });
      setRanks(fetchedData);
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (teamId) {
      getDocs(users).then((querySnapshot) => {
        const temp: any[] = [];
        querySnapshot.forEach((userDoc) => {
          temp.push({ ...userDoc.data(), id: userDoc.id });
        });

        setArchers(temp);
      });

      const unsub = onSnapshot(doc(db, "teams", teamId), (doc: any) => {
        setData(doc.data());
        form.setFieldsValue(doc.data());
        setLoading(false);
      });

      return unsub;
    }
  }, [teamId]);

  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        if (teamId) {
          const querySnapshot = await getDocs(users);
          querySnapshot.forEach((userDoc) => {
            if (teamId) {
              updateDoc(
                doc(db, "users", userDoc.id, "team", teamId),
                values
              ).then(() => {
                console.log("User updated");
              });
            }
          });

          updateDoc(doc(db, "teams", teamId), values).then(() => {
            message.success("Амжилттай.");
          });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      {data ? (
        <PageTitle
          loading={loading}
          title={`${data?.name}`}
          description={"Багийн профайл"}
          right={null}
          breadcrumbItems={[
            { link: "/teams", title: "Багууд" },
            { title: `${data?.name} ` }
          ]}
        />
      ) : (
        ""
      )}
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <Skeleton loading={loading} active>
                    <Form {...formItemLayout} form={form} onFinish={onFinish}>
                      <Form.Item
                        label="Нэр"
                        name="name"
                        rules={[{ required: true, message: "Нэр оруулна уу!" }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                          Хадгалах
                        </Button>
                      </Form.Item>
                    </Form>

                    <div className="table-responsive">
                      <h4>Харваачид</h4>
                      <Table
                        columns={columns}
                        dataSource={archers}
                        loading={loading}
                      />
                    </div>
                  </Skeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Team;
