import React, { useEffect, useState } from "react";
import { updateNamedExports } from "typescript";
import mock_data from "../../data.json";
import ArcherCard from "./ArcherCard";
import "./board.scss";
import EmptyCard from "./EmptyCard";
import dayjs from "dayjs";
import { Radio } from "antd";
import { ArcheryProp } from "../../_helpers/type";

const zogsool_dugaar = 4;
const Zurhai: any = React.forwardRef((prop, ref) => {
  const [page, setPage] = useState("1");
  const [rows, setRows] = useState([]);
  let hundii = 2;
  let startfromtestmen = 0;
  let startfromtestwomen = 0;
  let startfromtestlastmen = 0;

  const { competition, index, referees, men, women, lastmen, uy_number }: any =
    prop;
  const getRankName = (archer: any): string => {
    let rank: string = "";
    if (archer.rank.order <= 9) {
      rank = archer?.rank?.name;
    } else if (archer.rank.order > archer.rank2.order) {
      if (archer.rank.order <= 20 && archer.rank.order >= 12) {
        rank = archer?.rank?.name + ", " + archer?.rank2?.name;
      } else rank = archer?.rank2?.name;
    } else rank = archer?.rank?.name;
    return rank;
  };
  useEffect(() => {
    let uyfill: any = [];
    for (let u = 0; u < uy_number; u++) {
      let zogsoolfill: any = [];
      let nameofuy = "";
      let resultmen: any = [];
      let resultwomen = [];
      let resultlastmen = [];

      if (men.length <= 12) {
        console.log("13s baga", men.length, women.length, lastmen.length);
        if (u === 0) {
          nameofuy = "Мэргэ үе";
          resultmen = men.slice(
            startfromtestmen,
            startfromtestmen + zogsool_dugaar
          );
          resultmen.forEach((item: any, index: any) => {
            if (item) {
              zogsoolfill.push(
                <ArcherCard
                  item={item}
                  key={item.id}
                  id={item.id}
                  lastName={item.lastName}
                  firstName={item.firstName}
                  // "BhenrL9FHcZqJBuZdrqi" 2uulaa iim tsoltoi baival enuugeer
                  // ali neg ni uur baival teruugeer tsoliig haruulah
                  rating={getRankName(item)}
                  team={item.team.name}
                />
              );
            } else {
              zogsoolfill.push(<EmptyCard key={nameofuy + index} />);
            }
          });
          startfromtestmen = startfromtestmen + 4;
        } else if (u === uy_number - 1) {
          nameofuy = "Шувтрага мэргэ";
          resultlastmen = lastmen.slice(
            startfromtestlastmen,
            startfromtestlastmen + zogsool_dugaar
          );
          resultlastmen.forEach((item: any, index: any) => {
            if (item) {
              zogsoolfill.push(
                <ArcherCard
                  item={item}
                  key={item.id}
                  id={item.id}
                  lastName={item.lastName}
                  firstName={item.firstName}
                  rating={getRankName(item)}
                  team={item.team.name}
                />
              );
            } else {
              zogsoolfill.push(<EmptyCard key={nameofuy + index} />);
            }
          });
          startfromtestlastmen = startfromtestlastmen + 4;
        } else if (u === 1) {
          nameofuy = "Шил үе";
          resultwomen = women.slice(
            startfromtestwomen,
            startfromtestwomen + zogsool_dugaar
          );
          resultwomen.forEach((item: any, index: any) => {
            if (item) {
              zogsoolfill.push(
                <ArcherCard
                  item={item}
                  key={item.id}
                  id={item.id}
                  lastName={item.lastName}
                  firstName={item.firstName}
                  rating={getRankName(item)}
                  team={item.team.name}
                />
              );
            } else {
              zogsoolfill.push(<EmptyCard key={nameofuy + index} />);
            }
          });
          startfromtestwomen = startfromtestwomen + 4;
        } else if (u === 2) {
          nameofuy = "Ноён үе";
          resultmen = men.slice(
            startfromtestmen,
            startfromtestmen + zogsool_dugaar
          );
          if (resultmen.length > 0) {
            resultmen.forEach((item: any, index: any) => {
              if (item) {
                zogsoolfill.push(
                  <ArcherCard
                    item={item}
                    key={item.id}
                    id={item.id}
                    lastName={item.lastName}
                    firstName={item.firstName}
                    rating={getRankName(item)}
                    team={item.team.name}
                  />
                );
              } else {
                zogsoolfill.push(<EmptyCard key={nameofuy + index} />);
              }
            });
            startfromtestmen = startfromtestmen + 4;
          } else {
            resultwomen = women.slice(
              startfromtestwomen,
              startfromtestwomen + zogsool_dugaar
            );
            resultwomen.forEach((item: any, index: any) => {
              if (item) {
                zogsoolfill.push(
                  <ArcherCard
                    item={item}
                    key={item.id}
                    id={item.id}
                    lastName={item.lastName}
                    firstName={item.firstName}
                    rating={getRankName(item)}
                    team={item.team.name}
                  />
                );
              } else {
                zogsoolfill.push(<EmptyCard key={nameofuy + index} />);
              }
            });
            startfromtestwomen = startfromtestwomen + 4;
          }
        } else if (u === 3) {
          nameofuy = "Баатар үе";
          resultmen = men.slice(
            startfromtestmen,
            startfromtestmen + zogsool_dugaar
          );
          if (resultmen.length > 0) {
            for (let i = 0; i < 4; i++) {
              const item = resultmen[i];
              if (item) {
                zogsoolfill.push(
                  <ArcherCard
                    item={item}
                    key={item.id}
                    id={item.id}
                    lastName={item.lastName}
                    firstName={item.firstName}
                    rating={getRankName(item)}
                    team={item.team.name}
                  />
                );
              } else {
                zogsoolfill.push(<EmptyCard key={nameofuy + i} />);
              }
            }

            startfromtestmen = startfromtestmen + 4;
          } else {
            resultwomen = women.slice(
              startfromtestwomen,
              startfromtestwomen + zogsool_dugaar
            );
            for (let i = 0; i < 4; i++) {
              const item = resultwomen[i];

              if (item) {
                zogsoolfill.push(
                  <ArcherCard
                    item={item}
                    key={item.id}
                    id={item.id}
                    lastName={item.lastName}
                    firstName={item.firstName}
                    rating={getRankName(item)}
                    team={item.team.name}
                  />
                );
              } else {
                zogsoolfill.push(<EmptyCard key={nameofuy + i} />);
              }
            }

            startfromtestwomen = startfromtestwomen + 4;
          }
        } else if (u > 3 && u < uy_number - 1 && u !== uy_number) {
          nameofuy = `Хөндий ${hundii - 1}`;
          hundii++;
          // console.log("uy", u, "hundii", hundii);
          resultwomen = women.slice(
            startfromtestwomen,
            startfromtestwomen + zogsool_dugaar
          );

          if (resultwomen.length > 0) {
            for (let i = 0; i < 4; i++) {
              const item = resultwomen[i];

              if (item) {
                zogsoolfill.push(
                  <ArcherCard
                    item={item}
                    key={item.id}
                    id={item.id}
                    lastName={item.lastName}
                    firstName={item.firstName}
                    rating={getRankName(item)}
                    team={item.team.name}
                  />
                );
              } else {
                zogsoolfill.push(<EmptyCard key={nameofuy + i} />);
              }
            }

            startfromtestwomen = startfromtestwomen + 4;
          } else {
            resultmen = men.slice(
              startfromtestmen,
              startfromtestmen + zogsool_dugaar
            );
            if (resultmen.length > 0) {
              for (let i = 0; i < 4; i++) {
                const item = resultmen[i];
                if (item) {
                  zogsoolfill.push(
                    <ArcherCard
                      item={item}
                      key={item.id}
                      id={item.id}
                      lastName={item.lastName}
                      firstName={item.firstName}
                      rating={getRankName(item)}
                      team={item.team.name}
                    />
                  );
                } else {
                  zogsoolfill.push(<EmptyCard key={nameofuy + i} />);
                }
              }

              startfromtestmen = startfromtestmen + 4;
            }
          }
        }
      } else {
        if (u === 0 || u === 2 || u === 3 || u === 4) {
          nameofuy = "Мэргэ үе";
          if (u === 2) {
            nameofuy = "Ноён үе";
          } else if (u === 3) {
            nameofuy = "Баатар үе";
          } else if (u === 4) {
            nameofuy = "Хөндий 1";
          }
          resultmen = men.slice(
            startfromtestmen,
            startfromtestmen + zogsool_dugaar
          );
          resultmen.forEach((item: any, index: any) => {
            if (item) {
              zogsoolfill.push(
                <ArcherCard
                  item={item}
                  key={item.id}
                  id={item.id}
                  lastName={item.lastName}
                  firstName={item.firstName}
                  rating={getRankName(item)}
                  team={item.team.name}
                />
              );
            } else {
              zogsoolfill.push(<EmptyCard key={nameofuy + index} />);
            }
          });
          startfromtestmen = startfromtestmen + 4;
        } else if (u === 1) {
          nameofuy = "Шил үе";
          resultwomen = women.slice(
            startfromtestwomen,
            startfromtestwomen + zogsool_dugaar
          );
          resultwomen.forEach((item: any, index: any) => {
            if (item) {
              zogsoolfill.push(
                <ArcherCard
                  item={item}
                  key={item.id}
                  id={item.id}
                  lastName={item.lastName}
                  firstName={item.firstName}
                  rating={getRankName(item)}
                  team={item.team.name}
                />
              );
            } else {
              zogsoolfill.push(<EmptyCard key={nameofuy + index} />);
            }
          });
          startfromtestwomen = startfromtestwomen + 4;
        } else if (u > 4 && u < uy_number - 1 && u !== uy_number) {
          nameofuy = `Хөндий ${hundii}`;
          hundii++;
          let checkRank: any = [];
          resultwomen = women.slice(
            startfromtestwomen,
            startfromtestwomen + zogsool_dugaar
          );
          resultmen = men.slice(
            startfromtestmen,
            startfromtestmen + zogsool_dugaar
          );
          resultmen.forEach((item: any, index: any) => {
            if (item.rank2.order === 10) {
              checkRank.push(item.rank2.order);
            }
          });
          if (checkRank.includes("10")) {
            resultmen = men.slice(
              startfromtestmen,
              startfromtestmen + zogsool_dugaar
            );
            if (resultmen.length > 0) {
              for (let i = 0; i < 4; i++) {
                const item = resultmen[i];
                if (item) {
                  zogsoolfill.push(
                    <ArcherCard
                      item={item}
                      key={item.id}
                      id={item.id}
                      lastName={item.lastName}
                      firstName={item.firstName}
                      rating={getRankName(item)}
                      team={item.team.name}
                    />
                  );
                } else {
                  zogsoolfill.push(<EmptyCard key={nameofuy + i} />);
                }
              }

              startfromtestmen = startfromtestmen + 4;
            }
          } else if (resultwomen.length > 0) {
            for (let i = 0; i < 4; i++) {
              const item = resultwomen[i];

              if (item) {
                zogsoolfill.push(
                  <ArcherCard
                    item={item}
                    key={item.id}
                    id={item.id}
                    lastName={item.lastName}
                    firstName={item.firstName}
                    rating={getRankName(item)}
                    team={item.team.name}
                  />
                );
              } else {
                zogsoolfill.push(<EmptyCard key={nameofuy + i} />);
              }
            }
            startfromtestwomen = startfromtestwomen + 4;
          } else {
            resultmen = men.slice(
              startfromtestmen,
              startfromtestmen + zogsool_dugaar
            );
            if (resultmen.length > 0) {
              for (let i = 0; i < 4; i++) {
                const item = resultmen[i];
                if (item) {
                  zogsoolfill.push(
                    <ArcherCard
                      item={item}
                      key={item.id}
                      id={item.id}
                      lastName={item.lastName}
                      firstName={item.firstName}
                      rating={getRankName(item)}
                      team={item.team.name}
                    />
                  );
                } else {
                  zogsoolfill.push(<EmptyCard key={nameofuy + i} />);
                }
              }

              startfromtestmen = startfromtestmen + 4;
            }
          }
        } else {
          nameofuy = "Шувтрага мэргэ";
          resultlastmen = lastmen.slice(
            startfromtestlastmen,
            startfromtestlastmen + zogsool_dugaar
          );
          resultlastmen.forEach((item: any, index: any) => {
            if (item) {
              zogsoolfill.push(
                <ArcherCard
                  item={item}
                  key={item.id}
                  id={item.id}
                  lastName={item.lastName}
                  firstName={item.firstName}
                  rating={getRankName(item)}
                  team={item.team.name}
                />
              );
            } else {
              zogsoolfill.push(<EmptyCard key={nameofuy + index} />);
            }
          });
          startfromtestlastmen = startfromtestlastmen + 4;
        }
      }
      uyfill.push(
        <tr key={u}>
          <td width={40} className="name-of-ue">
            {nameofuy}
          </td>
          <td className="column-names">
            <tr>
              <td className="border-btm">Овог</td>
            </tr>
            <tr>
              <td className="border-btm">Нэр</td>
            </tr>
            <tr>
              <td className="border-btm name-team">Баг</td>
            </tr>
            <tr>
              <td className="border-btm name-team">Цол</td>
            </tr>
            <tr>
              <td className="border-none onoo-cell">Хана</td>
            </tr>
            <tr className="border-btm" />
            <tr>
              <td className="border-none onoo-cell">Хасаа</td>
            </tr>
            <tr className="border-btm" />
            <tr>
              <td className="border-none">Бүгд</td>
            </tr>
          </td>
          {zogsoolfill}
        </tr>
      );
    }

    const pageNum = parseInt(page);
    const sliced = uyfill.slice((pageNum - 1) * 3, pageNum * 3);
    setRows(sliced);
  }, [index, page, uy_number, men, women, lastmen]);

  const showPagination = () => {
    let pagination = [];
    let pageCount = Math.round(uy_number / 3);
    if (uy_number % 3 === 0) {
      pageCount = Math.round(uy_number / 3);
    } else {
      pageCount = Math.round(uy_number / 3) + 1;
    }
    for (let i = 1; i <= pageCount; i++) {
      pagination.push(<Radio.Button value={i.toString()}>{i}</Radio.Button>);
    }
    return pagination;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: 50,
          flexDirection: "column",
          width: 800
        }}
        // @ts-ignore
        ref={ref}
      >
        <div
          style={{
            minWidth: 800
          }}
        >
          <h2
            style={{
              fontSize: 18,
              textAlign: "center"
            }}
          >
            {competition.name}
          </h2>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <span>{`Огноо: ${dayjs(competition?.startDate).format(
              "YYYY.MM.DD"
            )} - ${dayjs(competition?.endDate).format("YYYY.MM.DD")}`}</span>
            <span>{`Зурхайн тоо: ${index + 1}`}</span>
          </div>
        </div>

        <table className="zurhai">
          <tbody>{rows}</tbody>
        </table>

        {referees && referees.length > 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              minWidth: 800
            }}
          >
            <p>{`Ерөнхий шүүгч: ${" .............................."}`}</p>
            <p>{`Самбарын шүүгч: ${" .............................."}`}</p>
            {/* <p>{`Ерөнхий шүүгч: ${competition?.referee?.lastName} ${competition?.referee?.firstName}`}</p>
            <p>{`Самбарын шүүгч: ${referees[index]?.lastName} ${referees[index]?.firstName}`}</p> */}
          </div>
        )}
      </div>
      <div
        style={{
          textAlign: "center"
        }}
      >
        <Radio.Group value={page} onChange={(e) => setPage(e.target.value)}>
          {showPagination()}
        </Radio.Group>
      </div>
    </>
  );
});

export default Zurhai;
