import { points } from "./points";
import dayjs from "dayjs";
import { ArcheryProp } from "./type";

export const sumPoints = (arr: number[]) =>
  arr?.reduce((acc, val) => acc + points[val].point, 0);

export const checkOnlyNumbers = (input: string): boolean => {
  const regex = /^[0-9]+$/;
  const value = Number(input);
  return regex.test(input) && value > 0;
};

export const orderArchers = (archers: any): ArcheryProp[] => {
  let ordered: ArcheryProp[] = [];
  let archersSeen = new Set();
  // Order #1
  let temp: any = [];
  archers.map((archer: any) => {
    if (parseInt(archer.rank.order) <= 9) {
      archersSeen.add(archer.id);
      temp.push(archer);
    }
  });

  temp.sort(
    (a: any, b: any) =>
      parseInt(a.rank.order) - parseInt(b.rank.order) ||
      dayjs(a.rateDate).diff(dayjs(b.rateDate))
  );
  ordered = [...temp];

  // Order #2
  temp = [];
  archers.map((archer: any) => {
    if (!archersSeen.has(archer.id)) {
      if (
        parseInt(archer.rank.order) >= 12 &&
        parseInt(archer.rank.order) <= 20 &&
        parseInt(archer.rank2.order) === 10
      ) {
        // archer.firstName = `${archer.firstName} - 2`;
        archersSeen.add(archer.id);
        temp.push(archer);
      }
    }
  });

  temp.sort(
    (a: any, b: any) =>
      parseInt(a.rank.order) - parseInt(b.rank.order) ||
      dayjs(a.rateDate2).diff(dayjs(b.rateDate2))
  );
  ordered = [...ordered, ...temp];

  // Order #3
  temp = [];
  archers.map((archer: any) => {
    if (!archersSeen.has(archer.id)) {
      if (parseInt(archer.rank2?.order) === 10) {
        // archer.firstName = `${archer.firstName} - 3`;
        archersSeen.add(archer.id);
        temp.push(archer);
      }
    }
  });

  temp.sort(
    (a: any, b: any) =>
      parseInt(a.rank2?.order) - parseInt(b.rank2?.order) ||
      dayjs(a.rateDate2).diff(dayjs(b.rateDate2))
  );
  ordered = [...ordered, ...temp];

  // Order #4
  temp = [];
  archers.map((archer: any) => {
    if (!archersSeen.has(archer.id)) {
      if (
        parseInt(archer.rank.order) >= 12 &&
        parseInt(archer.rank.order) <= 20 &&
        parseInt(archer.rank2.order) === 11
      ) {
        // archer.firstName = `${archer.firstName} - 4`;
        archersSeen.add(archer.id);
        temp.push(archer);
      }
    }
  });

  temp.sort(
    (a: any, b: any) =>
      parseInt(a.rank.order) - parseInt(b.rank.order) ||
      dayjs(a.rateDate2).diff(dayjs(b.rateDate2))
  );
  ordered = [...ordered, ...temp];

  // Order #5
  temp = [];
  archers.map((archer: any) => {
    if (!archersSeen.has(archer.id)) {
      if (parseInt(archer.rank2?.order) === 11) {
        // archer.firstName = `${archer.firstName} - 5`;
        archersSeen.add(archer.id);
        temp.push(archer);
      }
    }
  });

  temp.sort(
    (a: any, b: any) =>
      parseInt(a.rank2.order) - parseInt(b.rank2.order) ||
      dayjs(a.rateDate2).diff(dayjs(b.rateDate2))
  );
  ordered = [...ordered, ...temp];

  // Order #6
  temp = [];
  archers.map((archer: any) => {
    if (
      parseInt(archer.rank.order) >= 12 &&
      parseInt(archer.rank.order) <= 20 &&
      parseInt(archer.rank2.order) !== 11 &&
      parseInt(archer.rank2.order) !== 10
    ) {
      // archer.firstName = `${archer.firstName} - 6`;
      archersSeen.add(archer.id);
      temp.push(archer);
    }
  });

  temp.sort(
    (a: any, b: any) =>
      parseInt(a.rank.order) - parseInt(b.rank.order) ||
      dayjs(a.rateDate).diff(dayjs(b.rateDate))
  );
  ordered = [...ordered, ...temp];

  // Order #7
  temp = [];
  archers.map((archer: any) => {
    if (
      parseInt(archer.rank.order) >= 27 &&
      parseInt(archer.rank.order) <= 29 &&
      parseInt(archer.rank2?.order) !== 11 &&
      parseInt(archer.rank2?.order) !== 10
    ) {
      // archer.firstName = `${archer.firstName} - 6`;
      archersSeen.add(archer.id);
      temp.push(archer);
    }
  });

  temp.sort(
    (a: any, b: any) =>
      parseInt(a.rank.order) - parseInt(b.rank.order) ||
      dayjs(a.rateDate).diff(dayjs(b.rateDate))
  );
  ordered = [...ordered, ...temp];

  // Order #8
  temp = [];
  archers.map((archer: any) => {
    if (
      parseInt(archer.rank.order) === 33 &&
      (parseInt(archer.rank2.order) === 30 ||
        parseInt(archer.rank2.order) === 31 ||
        parseInt(archer.rank2.order) === 32)
    ) {
      // archer.firstName = `${archer.firstName} - 6`;
      archersSeen.add(archer.id);
      temp.push(archer);
    }
  });

  temp.sort(
    (a: any, b: any) =>
      parseInt(a.rank2.order) - parseInt(b.rank2.order) ||
      dayjs(a.rateDate2).diff(dayjs(b.rateDate2))
  );
  ordered = [...ordered, ...temp];

  // Order #9
  temp = [];
  let birthNum = 0;
  archers.map((archer: any) => {
    birthNum = archer.ssn.slice(2, 10);
    if (!archersSeen.has(archer.id) && birthNum >= 10000000) {
      // archer.firstName = `${archer.firstName} - 7`;
      archersSeen.add(archer.id);
      temp.push(archer);
    }
  });

  temp.sort(
    (a: any, b: any) =>
      // parseInt(a.rank.order) - parseInt(b.rank.order) ||
      parseInt(a.ssn.slice(2, 10)) - parseInt(b.ssn.slice(2, 10))
    // const font = item.ssn.slice(2, 10);
  );
  ordered = [...ordered, ...temp];

  // Order #10
  temp = [];
  birthNum = 0;
  archers.map((archer: any) => {
    birthNum = archer.ssn.slice(2, 10);
    if (!archersSeen.has(archer.id) && birthNum <= 10000000) {
      // archer.firstName = `${archer.firstName} - 7`;
      archersSeen.add(archer.id);
      temp.push(archer);
    }
  });

  temp.sort(
    (a: any, b: any) =>
      // parseInt(a.rank.order) - parseInt(b.rank.order) ||
      parseInt(a.ssn.slice(2, 10)) - parseInt(b.ssn.slice(2, 10))
    // const font = item.ssn.slice(2, 10);
  );
  ordered = [...ordered, ...temp];
  ordered.map((item, i) => (item.index = i + 1));

  return ordered;
};
