import { PageTitle } from "../../components";
import Menu from "../../components/VerticalMenu";

function Home() {
  return (
    <>
      <PageTitle title="Тавтай морилно уу" description="Нүүр хуудас" />
    </>
  );
}
export default Home;
