import { useState, useEffect, useRef } from "react";
import { PageTitle } from "../../components";
import {
  Button,
  Form,
  Input,
  message,
  Skeleton,
  Upload,
  Popconfirm,
} from "antd";
import { db } from "../../firebase";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { NewsProp } from "../../_helpers/type";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";

const storage = getStorage();

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

function NewsDetail() {
  const [form] = Form.useForm();
  let { newsId } = useParams();
  const [value, setValue] = useState("");
  const [data, setData] = useState<NewsProp | null>(null);
  const [newsImage, setNewsImage] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [newsImageUrl, setNewsImageUrl] = useState<string | null>();
  const [newsImageName, setNewsImageName] = useState<string | null>();
  const logoLoading = useSelector(
    (state: any) => state.firebaseUploderReducer.onProgress
  );
  const userData = useSelector((state: any) => state.authentication.userData);
  const editorRef = useRef(null);

  useEffect(() => {
    if (newsId) {
      const unsub = onSnapshot(doc(db, "news", newsId), (doc: any) => {
        const docData = doc.data();
        setData(docData);
        form.setFieldsValue(docData);
        if (docData?.imageUrl) {
          setNewsImageUrl(docData?.imageUrl);
          setNewsImageName(docData?.imageName);
        }
        setValue(docData.content);
        setLoading(false);
      });

      return unsub;
    }
  }, [newsId]);

  const onFinish = () => {
    // @ts-ignore
    const content =
      // @ts-ignore
      editorRef.current.getEditor().getLength() > 1
        ? // @ts-ignore
          editorRef.current.getEditor().getText().trim().length
        : 0;

    if (content <= 0) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
    }
    form
      .validateFields()
      .then(async (values) => {
        if (newsId) {
          setFormLoading(true);
          let imageUrl = "";
          let imageName = "";
          if (newsImage && newsImage !== null) {
            try {
              const originalExtension = newsImage.name.split(".").pop();
              const storageRef = ref(
                storage,
                `newsImage/${newsId}.${originalExtension}`
              );
              await uploadBytes(storageRef, newsImage);
              imageUrl = await getDownloadURL(storageRef);
              imageName = `${newsId}.${originalExtension}`;
              console.log("Upload successful");
            } catch (error) {
              console.error("Error uploading image:", error);
            }
          }

          setDoc(
            doc(db, "news", newsId),
            {
              title: values.title,
              description: values.description,
              content: value,
              updatedAt: new Date(),
              imageUrl,
              imageName,
              author: userData,
            },
            { merge: true }
          ).then(() => {
            setFormLoading(false);
            message.success("Мэдээ амжилттай засагдлаа");
          });
        }
      })
      .catch((info) => {
        setFormLoading(false);
        console.log("Validate Failed:", info);
      });
  };

  const uploadButton = (
    <div>
      {logoLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const customRequest = ({ file, onSuccess, onError }: any) => {
    setNewsImage(file);
    onSuccess();
  };

  const confirm = async (e: any) => {
    try {
      const storageRef = ref(storage, `newsImage/${newsImageName}`);
      await deleteObject(storageRef);
      if (newsId) {
        setDoc(
          doc(db, "news", newsId),
          {
            imageUrl: null,
            imageName: null,
          },
          { merge: true }
        ).then(() => {
          message.success("Амжилттай");
          setNewsImageUrl(null);
          setNewsImageName(null);
        });
      }
    } catch (error) {
      message.error("Зураг устгахад алдаа гарлаа.");
      console.error("Error uploading image:", error);
    }
  };

  const cancel = (e: any) => {
    console.log(e);
  };

  return (
    <>
      {data ? (
        <PageTitle
          loading={loading}
          title={data?.title}
          right={null}
          breadcrumbItems={[
            { link: "/news", title: "Мэдээ" },
            { title: data?.title },
          ]}
        />
      ) : (
        ""
      )}
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <Skeleton loading={loading} active>
                    <Form
                      {...formItemLayout}
                      form={form}
                      onFinish={() => onFinish()}
                    >
                      <Form.Item
                        label="Нэр"
                        name="title"
                        rules={[{ required: true, message: "Нэр оруулна уу!" }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Товч"
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: "Товч мэдээлэл оруулна уу!",
                          },
                        ]}
                      >
                        <Input.TextArea />
                      </Form.Item>

                      <Form.Item
                        label="Мэдээний зураг"
                        valuePropName="newsImage"
                      >
                        {newsImageUrl ? (
                          <div className="delete-image">
                            <img
                              src={newsImageUrl}
                              alt="logo"
                              style={{ width: 150 }}
                            />
                            <Popconfirm
                              title="Мэдээний зураг устгах уу?"
                              // description="Are you sure to delete this task?"
                              onConfirm={confirm}
                              onCancel={cancel}
                              okText="Тийм"
                              cancelText="Үгүй"
                            >
                              <Button danger>Устгах</Button>
                            </Popconfirm>
                          </div>
                        ) : (
                          <Upload
                            beforeUpload={(file) => {
                              const isPNG =
                                file.type === "image/png" ||
                                file.type === "image/jpg" ||
                                file.type === "image/jpeg";
                              if (!isPNG) {
                                message.error(`Зургийн файл буруу байна`);
                              }
                              return isPNG || Upload.LIST_IGNORE;
                            }}
                            customRequest={customRequest}
                            fileList={newsImage ? [newsImage] : []}
                            listType="picture-card"
                          >
                            {uploadButton}
                          </Upload>
                        )}
                      </Form.Item>
                      <Form.Item
                        label="Мэдээлэл"
                        style={{ height: 200, marginBottom: 100 }}
                      >
                        {/* <EditorToolbar /> */}
                        <div style={{ minHeight: 200 }}>
                          <ReactQuill
                            ref={editorRef}
                            theme="snow"
                            value={value}
                            onChange={setValue}
                            // modules={modules}
                            // formats={formats}
                            style={{
                              maxHeight: 150,
                            }}
                          />
                        </div>
                        {showError && (
                          <span style={{ color: "red" }}>
                            Мэдээлэл оруулна уу
                          </span>
                        )}
                      </Form.Item>

                      <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={formLoading}
                        >
                          Хадгалах
                        </Button>
                        <span
                          style={{
                            fontSize: 10,
                            marginLeft: 10,
                            fontStyle: "italic",
                            color: "#ccc",
                          }}
                        >
                          {/* @ts-ignore */}
                          {dayjs(data?.updatedAt?.toDate()).format(
                            "YYYY-MM-DD HH:mm"
                          )}
                        </span>
                      </Form.Item>
                    </Form>
                  </Skeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default NewsDetail;
