export const firebaseUploderReducer = (state = {}, action: any) => {
  switch (action.type) {
    case "FIREBASE_UPLOAD_PROGRESS":
      return { onProgress: true, onComplete: false };
    case "FIREBASE_UPLOAD_ERROR":
      return {
        onProgress: false,
        onComplete: false,
        uploadError: action.payload,
      };
    case "FIREBASE_UPLOAD_END":
      return { onProgress: false, onComplete: true, fileUrl: action.payload };
    case "FIREBASE_UPLOAD_CLEAR":
      return {};
    default:
      return state;
  }
};
