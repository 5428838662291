import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import { useSelector } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";

const { Header } = Layout;

function MainHeader({ setCollapsed }: any) {
  const [isOpen, setIsOpen] = useState(false);
  const userData = useSelector((state: any) => state.authentication.userData);

  return (
    <Header style={{ padding: 0, height: 70 }}>
      <div className="navbar-header">
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-sm px-3 font-size-24 header-item waves-effect"
            id="vertical-menu-btn"
            onClick={() => setCollapsed()}
          >
            {/* <Icon name="left-indent" color="#e9ecef" /> */}
          </button>
        </div>

        <div className="d-flex align-items-center justify-content-center">
          <div className="dropdown d-inline-block">
            <OutsideClickHandler
              onOutsideClick={() => (isOpen ? setIsOpen(false) : null)}
            >
              <button
                type="button"
                className="btn header-item waves-effect"
                onClick={() => setIsOpen(!isOpen)}
              >
                <span
                  className="d-none d-sm-inline-block ml-1"
                  style={{ color: "#fff" }}
                >
                  {userData ? userData.lastName + " " + userData.firstName : ""}
                </span>
                <i className="mdi mdi-chevron-down d-none d-sm-inline-block"></i>
              </button>
            </OutsideClickHandler>
            <div
              className={`dropdown-menu dropdown-menu-right ${
                isOpen ? "show" : ""
              }`}
            >
              {/* <Link className="dropdown-item" to="/profile">
                <i className="mdi mdi-face-profile font-size-16 align-middle mr-1"></i>
                Профайл
              </Link> */}
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item" to="/login">
                <i className="mdi mdi-logout font-size-16 align-middle mr-1"></i>
                Гарах
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Header>
  );
}

export default MainHeader;
