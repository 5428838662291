import { REHYDRATE } from "redux-persist";
const initialState = {
  loggedIn: false,
  userData: {},
};

export function authentication(state = initialState, action: any) {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload) {
        return {
          ...state,
          loggedIn: action.payload.authentication.loggedIn
            ? action.payload.authentication.loggedIn
            : false,
          userData: action.payload.authentication.userData
            ? action.payload.authentication.userData
            : {},
        };
      } else {
        return {
          ...state,
        };
      }
    case "SET_USER": {
      return {
        loggedIn: true,
        userData: action.payload,
      };
    }
    case "LOG_OUT": {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
