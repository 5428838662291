import React, { useEffect, useState } from "react";
import { Button } from "antd";

import dayjs from "dayjs";
import Zurhai from "./Zurhai";
import "./board.scss";
import { points } from "../../_helpers";
import { db } from "../../firebase";
import {
  collection,
  doc,
  onSnapshot,
  getDocs,
  query,
  where,
  updateDoc,
  setDoc,
  addDoc,
  deleteDoc,
  arrayUnion,
  DocumentData,
  DocumentReference
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import { sumPoints } from "../../_helpers/utils";
import { ArcheryProp } from "../../_helpers/type";
import { useSelector } from "react-redux";

interface ArcherCardProps {
  item: any;
  id: string;
  lastName: string;
  firstName: string;
  team: string;
  rating: string;
}

const ArcherCard = ({
  item,
  id,
  lastName,
  firstName,
  team,
  rating
}: ArcherCardProps) => {
  const [hanaPoints, setHanaPoints] = useState<number[]>([]);
  const [hasPoints, setHasPoints] = useState<number[]>([]);
  const [archerData, setArcherData] = useState<any>();
  const ranks = useSelector((state: any) => state.content.ranks);
  const { competitionId } = useParams();
  const userData = useSelector((state: any) => state.authentication.userData);
  let docRef: DocumentReference<DocumentData>;

  if (competitionId) {
    docRef = doc(db, "competitions", competitionId, "archers", id);
  }

  useEffect(() => {
    const unsub = onSnapshot(docRef, (querySnapshot) => {
      const userData = querySnapshot.data();
      setHanaPoints(userData?.hanaPoints);
      setHasPoints(userData?.hasPoints);
      setArcherData(userData);
    });

    return unsub;
  }, []);

  const handleClick = async (type: string, point: number) => {
    let total = sumPoints(hanaPoints) + sumPoints(hasPoints) + 1;
    let totalLength = hanaPoints.length + hasPoints.length + 1;
    if (archerData.rank.order >= 9 || archerData.rank2.order >= 11) {
      let currentRankMax = archerData.rank2.maxPoint;
      if (currentRankMax < total) {
        for (const rank in ranks) {
          if (ranks[rank].isRank === true) {
            if (
              ranks[rank].order === 32 &&
              ranks[rank].minPoint <= total &&
              ranks[rank].maxPoint >= total
            ) {
              console.log(
                "%d сумнаас %d онож %s цолны болзол биелүүллээ.",
                totalLength,
                total,
                ranks[rank].name
              );
            } else if (
              ranks[rank].order === 31 &&
              ranks[rank].minPoint <= total &&
              ranks[rank].maxPoint >= total
            ) {
              console.log(
                "%d сумнаас %d онож %s цолны болзол биелүүллээ.",
                totalLength,
                total,
                ranks[rank].name
              );
            } else if (
              ranks[rank].order === 30 &&
              ranks[rank].minPoint <= total &&
              ranks[rank].maxPoint >= total
            ) {
              console.log(
                "%d сумнаас %d онож %s цолны болзол биелүүллээ.",
                totalLength,
                total,
                ranks[rank].name
              );
            } else if (
              ranks[rank].order === 11 &&
              ranks[rank].minPoint <= total &&
              ranks[rank].maxPoint >= total
            ) {
              console.log(
                "%d сумнаас %d онож %s цолны болзол биелүүллээ.",
                totalLength,
                total,
                ranks[rank].name
              );
            } else if (
              ranks[rank].order === 10 &&
              ranks[rank].minPoint <= total
            ) {
              console.log(
                "%d сумнаас %d онож %s цолны болзол биелүүллээ.",
                totalLength,
                total,
                ranks[rank].name
              );
            }
          }
        }
      } else console.log("not yet");
      // TODO: totalPoints hadgaldag bolgoh
    }
    if (type === "Хана") {
      if (hanaPoints.length === 20) {
        alert("Хана нийлсэн");
        return;
      }
      updateDoc(docRef, {
        hanaPoints: [...hanaPoints, point]
      });
    } else {
      if (hasPoints.length === 20) {
        alert("Хасаа нийлсэн");
        return;
      }
      updateDoc(docRef, {
        hasPoints: [...hasPoints, point]
      });
    }
  };

  const handleRemovePoint = async (type: string) => {
    if (type === "Хана") {
      hanaPoints.pop();

      updateDoc(docRef, {
        hanaPoints: [...hanaPoints]
      });
    } else {
      hasPoints.pop();

      updateDoc(docRef, {
        hasPoints: [...hasPoints]
      });
    }
  };

  return (
    <td>
      <tr>
        <td colSpan={3} className="border-btm">
          {lastName}
        </td>
      </tr>
      <tr>
        <td colSpan={3} className="border-btm">
          {firstName}
        </td>
      </tr>
      <tr>
        <td colSpan={3} className="border-btm name-team">
          {team}
        </td>
      </tr>
      <tr>
        <td colSpan={3} className="border-btm name-team">
          {rating}
          {/* {item.rank.name}: {item.rank.order}:{" "}
          {dayjs(item.rateDate).format("YYYY-MM-DD")}
          <br />
          {item.rank2.name}: {item.rank2.order}:{" "}
          {dayjs(item.rateDate2).format("YYYY-MM-DD")} :{" "}
          {item.rank2?.isRank && "yes"} */}
        </td>
      </tr>
      <tr>
        <td className="border-none onoo-cell">
          {hanaPoints?.slice(0, 8).map((el: any) => (
            <img
              alt={points[el].label.toString()}
              src={points[el].line}
              width={6}
            />
          ))}
        </td>
        <td className="border-none rotate-line onoo-cell">
          {hanaPoints
            ?.slice(8, 16)
            .reverse()
            .map((el: any) => (
              <img
                alt={points[el].label.toString()}
                src={points[el].line}
                width={6}
              />
            ))}
        </td>
        <td className="border-none onoo-cell">
          {hanaPoints?.slice(16, 20).map((el: any) => (
            <img
              alt={points[el].label.toString()}
              src={points[el].line}
              width={6}
            />
          ))}
        </td>
      </tr>
      <tr>
        <td className="border-btm"></td>
        <td>{sumPoints(hanaPoints) == 0 ? "" : sumPoints(hanaPoints)}</td>
        <td className="border-btm"></td>
      </tr>
      <tr className="backgroundcolorzuraas">
        <td className="border-none onoo-cell">
          {hasPoints?.slice(0, 8).map((el: any) => (
            <img
              alt={points[el].label.toString()}
              src={points[el].line}
              width={6}
            />
          ))}
        </td>
        <td className="border-none rotate-line onoo-cell">
          {hasPoints
            ?.slice(8, 16)
            .reverse()
            .map((el: any) => (
              <img
                alt={points[el].label.toString()}
                src={points[el].line}
                width={6}
              />
            ))}
        </td>
        <td className="border-none onoo-cell">
          {hasPoints?.slice(16, 20).map((el: any) => (
            <img
              alt={points[el].label.toString()}
              src={points[el].line}
              width={6}
            />
          ))}
        </td>
      </tr>
      <tr>
        <td className="border-btm"></td>
        <td>{sumPoints(hasPoints) == 0 ? "" : sumPoints(hasPoints)}</td>
        <td className="border-btm"></td>
      </tr>
      <tr>
        <td colSpan={3} className="border-none">
          {sumPoints(hanaPoints) + sumPoints(hasPoints) == 0
            ? ""
            : sumPoints(hanaPoints) + sumPoints(hasPoints)}
        </td>
      </tr>

      {(userData.role === "admin" || userData.role === "referee") &&
        hanaPoints &&
        hanaPoints.length < 20 && (
          <div>
            <span className="height-6" onClick={() => handleClick("Хана", 1)}>
              Оносон
            </span>
            <span className="height-6" onClick={() => handleClick("Хана", 2)}>
              Дутсан
            </span>
            <span className="height-6" onClick={() => handleClick("Хана", 4)}>
              Дүүрсэн
            </span>
            <span className="height-6" onClick={() => handleClick("Хана", 0)}>
              Давсан
            </span>
            <span className="height-6" onClick={() => handleClick("Хана", 3)}>
              Хазгай
            </span>
            <tr></tr>
          </div>
        )}

      {userData.role === "admin" &&
        hanaPoints.length > 0 &&
        hanaPoints.length <= 20 && (
          <button onClick={() => handleRemovePoint("Хана")}>Хасах</button>
        )}

      {(userData.role === "admin" || userData.role === "referee") &&
        hanaPoints.length >= 20 &&
        hasPoints &&
        hasPoints.length < 20 && (
          <div>
            <span className="height-6" onClick={() => handleClick("Хасаа", 5)}>
              Оносон
            </span>
            <span className="height-6" onClick={() => handleClick("Хасаа", 2)}>
              Дутсан
            </span>
            <span className="height-6" onClick={() => handleClick("Хасаа", 6)}>
              Дүүрсэн
            </span>
            <span className="height-6" onClick={() => handleClick("Хасаа", 0)}>
              Давсан
            </span>
            <span className="height-6" onClick={() => handleClick("Хасаа", 3)}>
              Хазгай
            </span>
          </div>
        )}
      {userData.role === "admin" &&
        hasPoints.length > 0 &&
        hasPoints.length <= 20 && (
          <button onClick={() => handleRemovePoint("Хасаа")}>Хасах</button>
        )}
    </td>
  );
};

export default ArcherCard;
