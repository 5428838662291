import React, { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";
import { db, auth } from "../../firebase";
import {
  getDoc,
  collection,
  doc,
  getDocs,
  onSnapshot
} from "firebase/firestore";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOut, signInWithEmailAndPassword } from "firebase/auth";
const adminUsersRef = collection(db, "adminUsers");
const usersRef = collection(db, "users");

function Login() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [buttonLoading, setLoading] = useState(false);

  useEffect(() => {
    signOut(auth)
      .then(() => {
        dispatch({ type: "LOG_OUT" });
      })
      .catch((error: any) => {
        // An error happened.
      });
  }, []);

  const onFinish = async (values: any) => {
    const { username, password } = values;
    if (username && password) {
      setLoading(true);

      try {
        signInWithEmailAndPassword(auth, username, password)
          .then((userCredential) => {
            getDoc(doc(adminUsersRef, username)).then(async (snapshot) => {
              if (snapshot.exists()) {
                const userData = snapshot.data();

                // getRanks
                const ranks = getRanks();
                // getTeams
                const teams = getTeams();

                dispatch({ type: "SET_USER", payload: userData });
                dispatch({ type: "SET_RANKS", payload: ranks });
                dispatch({ type: "SET_TEAMS", payload: teams });

                navigate("/", { replace: true });
              } else {
                getDoc(doc(usersRef, username)).then(async (snapshot) => {
                  if (snapshot.exists()) {
                    const userData = snapshot.data();

                    if (userData.isTeamLeader) {
                      // getRanks
                      const ranks = await getRanks();
                      // getTeams
                      const teams = await getTeams();

                      dispatch({
                        type: "SET_USER",
                        payload: { ...userData, role: "leader" }
                      });
                      dispatch({ type: "SET_RANKS", payload: ranks });
                      dispatch({ type: "SET_TEAMS", payload: teams });

                      navigate("/", { replace: true });
                    } else {
                      alert("Нэвтрэх мэдээлэл буруу байна");
                      setLoading(false);
                    }
                  } else {
                    alert("Нэвтрэх мэдээлэл буруу байна");
                    setLoading(false);
                  }
                });
              }
            });
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            alert("Нэвтрэх мэдээлэл буруу байна");
            setLoading(false);
          });
      } catch (err: any) {
        setLoading(false);
        alert(err.message);
      }
    }
  };

  const getRanks = () => {
    const fetchedData: any = {};
    onSnapshot(collection(db, "ranks"), (querySnapshot: any) => {
      querySnapshot.forEach((doc: any) => {
        fetchedData[doc.id] = {
          id: doc.id,
          ...doc.data()
        };
      });
    });

    return fetchedData;
  };

  const getTeams = () => {
    const fetchedData: any = {};
    onSnapshot(collection(db, "teams"), (querySnapshot: any) => {
      querySnapshot.forEach((doc: any) => {
        fetchedData[doc.id] = {
          id: doc.id,
          ...doc.data()
        };
      });
    });

    return fetchedData;
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="bg-primary bg-pattern vh-100">
      <div className="account-pages pt-sm-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center mb-5">
                <img
                  src={require("../../assets/images/logo-da.png")}
                  height="150"
                  alt="logo"
                />
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-5 col-sm-8">
              <div className="card">
                <div className="card-body p-4">
                  <div className="p-2">
                    <h5 className="mb-5 text-center">Системд нэвтрэх</h5>
                    <Form
                      className="form-horizontal"
                      form={form}
                      name="login-form"
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      layout="vertical"
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group mb-4">
                            <Form.Item
                              label="Имэйл хаяг"
                              name="username"
                              rules={[
                                {
                                  required: true,
                                  message: "Имэйл хаяг оруулна уу!"
                                }
                              ]}
                            >
                              <Input type="text" />
                            </Form.Item>
                          </div>

                          <div className="form-group mb-4">
                            <Form.Item
                              label="Нууц үг"
                              name="password"
                              rules={[
                                {
                                  required: true,
                                  message: "Нууц үгээ оруулна уу!"
                                }
                              ]}
                            >
                              <Input.Password />
                            </Form.Item>
                          </div>

                          <div className="mt-4">
                            <Button
                              type="primary"
                              size="large"
                              className={
                                "btn btn-success btn-block waves-effect waves-light"
                              }
                              htmlType="submit"
                              loading={buttonLoading}
                            >
                              Нэвтрэх
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="version-code">v1.0.0</p>
    </div>
  );
}

export default Login;
