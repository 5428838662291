import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, redirect } from "react-router-dom";
import { Layout } from "antd";
import { MainHeader, VerticalMenu } from "./";
const { Content, Footer, Sider } = Layout;

const PrivateRoute: any = ({ children }: { children: JSX.Element }) => {
  const userData = useSelector((state: any) => state.authentication.userData);

  const [collapsed, setCollapsed] = useState(false);
  const isLoggedIn = useSelector((state: any) => state.authentication.loggedIn);

  if (!isLoggedIn) return redirect("/login");

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {userData.role === "admin" && (
        <Sider collapsed={collapsed} width={250}>
          <a href="/" className="logo">
            <span className="logo-sm">
              <img src={require("../assets/images/logo-da.png")} />
            </span>
            <span className="logo-lg">
              <img src={require("../assets/images/logo-da.png")} />
            </span>
          </a>
          <VerticalMenu />
        </Sider>
      )}
      <Layout className="site-layout">
        <MainHeader setCollapsed={() => setCollapsed(!collapsed)} />
        <Content>{children}</Content>
        <Footer style={{ textAlign: "center" }}>
          Монголын Үндэсний Харваачдын Холбоо ©2023 <br></br>
          DEVELOPED BY DIGITAL ARCHERY LLC
        </Footer>
      </Layout>
    </Layout>
  );
};

export default PrivateRoute;
