import { useLocation } from "react-router-dom";
import { Menu } from "antd";
import { routes } from "../_helpers";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const { SubMenu } = Menu;

function VerticalMenu() {
  let location = useLocation();
  const userData = useSelector((state: any) => state.authentication.userData);

  const getNavLinkClass = (path: any) => {
    return location.pathname === path ? "mm-active" : "";
  };

  return (
    <div>
      <Menu mode="inline">
        {routes.map((nav: any) => {
          if (userData.role === "teamLeader") {
            if (nav.key == "ranks") return;
          }
          let childs: any;
          if (nav.childrens !== undefined && nav.childrens.length > 0) {
            const subMenus = nav.childrens;
            subMenus.map((item: any) => {
              childs.push(
                <Menu.Item key={item.path}>
                  <NavLink to={item.path}>
                    <div className="d-inline-block icons-sm mr-1">
                      {/* <Icon name={item.icon} /> */}
                    </div>
                    <span>{item.label}</span>
                  </NavLink>
                </Menu.Item>
              );
            });
            return (
              <SubMenu
                key={nav.path}
                // icon={<Icon name={nav.icon} />}
                className={getNavLinkClass(nav.path)}
                title={nav.label}
              >
                {childs}
              </SubMenu>
            );
          } else {
            return (
              <Menu.Item key={nav.path} className={getNavLinkClass(nav.path)}>
                <NavLink to={nav.path}>
                  <div className="d-inline-block icons-sm mr-1">
                    {/* <Icon name={nav.icon} /> */}
                  </div>
                  <span>{nav.label}</span>
                </NavLink>
              </Menu.Item>
            );
          }
        })}
      </Menu>
    </div>
  );
}

export default VerticalMenu;
