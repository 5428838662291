import React, { useState, useEffect } from "react";
import { PageTitle } from "../../components";
import {
  Table,
  Space,
  Button,
  Form,
  Input,
  Select,
  Modal,
  DatePicker,
  Radio,
  Popconfirm,
  message,
  Checkbox,
  notification,
} from "antd";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  deleteDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { PlusOutlined } from "@ant-design/icons";
import { db } from "../../firebase";
import { Link, Outlet } from "react-router-dom";
import dayjs from "dayjs";
import type { ColumnsType } from "antd/lib/table";
import { API } from "../../_helpers";
import { RankProp } from "../../_helpers/type";
import { useSelector } from "react-redux";
import datatest from "../../datatest.json";
import { orderArchers } from "../../_helpers/utils";
import { ArcheryProp } from "../../_helpers/type";

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 18 },
};

const { Option } = Select;

function Erembe() {
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();
  const [menList, setMenList] = useState<ArcheryProp[]>([]);
  const [womenList, setWomenList] = useState<ArcheryProp[]>([]);
  const [menOldData, setMenOldData] = useState<ArcheryProp[]>([]);
  const [womenOldData, setWomenOldData] = useState<ArcheryProp[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchKey, setSearchKey] = useState("firstName");
  const [searchGender, setGender] = useState<string>("male");
  const [searchValue, setSearchValue] = useState<any>(null);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const ranks = useSelector((state: any) => state.content.ranks);
  const teams = useSelector((state: any) => state.content.teams);
  const userData = useSelector((state: any) => state.authentication.userData);

  useEffect(() => {
    const unsub = onSnapshot(
      query(collection(db, "users")),
      (querySnapshot) => {
        const comps: ArcheryProp[] = [];

        querySnapshot.forEach((doc: any) => {
          const docData = doc.data();
          if (docData?.role !== "archer") return;
          comps.push({
            ...docData,
            key: doc.id,
            id: doc.id,
            rank: ranks[docData.rankId],
            rank2: ranks[docData.rate2],
          });
        });
        let men = comps.filter((archer: any) => archer.gender === "male");
        let women = comps.filter((archer: any) => archer.gender === "female");
        men = orderArchers(men);
        women = orderArchers(women);
        setMenList(men);
        setWomenList(women);
        setLoading(false);
        setWomenOldData(women);
        setMenOldData(men);
      }
    );

    return unsub;
  }, []);

  const onFinish = () => {
    setLoading(true);

    console.log(menList.length, womenList.length);
    const data = searchGender === "male" ? menList : womenList;
    const comps: ArcheryProp[] = data.filter((item: any): boolean =>
      item[searchKey]?.toLowerCase().includes(searchValue?.toLowerCase())
    );
    setLoading(false);
    if (searchGender === "male") {
      setMenList(comps);
    } else setWomenList(comps);
  };

  const clearSearchForm = () => {
    searchForm.resetFields();
    setSearchValue(null);
    setMenList(menOldData);
    setWomenList(womenOldData);
  };

  const columns: ColumnsType<ArcheryProp> = [
    {
      title: "Эрэмбэ",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Овог",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Нэр",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Регистрийн дугаар",
      dataIndex: "ssn",
      key: "ssn",
    },
    {
      title: "Улсын цол",
      dataIndex: "rankId",
      key: "rankId",
      render: (value, record) => {
        const currentRank = ranks[value];

        return currentRank ? (
          currentRank.name
        ) : (
          <span style={{ color: "red" }}>Цол сонгоогүй</span>
        );
      },
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => a.currentRank - b.currentRank,
    },
    {
      title: "Улсын цол авсан огноо",
      dataIndex: "rateDate",
      key: "rateDate",
      render: (value) => {
        return dayjs(value).format("YYYY-MM-DD");
      },
    },
    {
      title: "Спортын зэрэг",
      dataIndex: "rate2",
      key: "rate2",
      render: (value, record) => {
        const currentRank = ranks[value];

        return currentRank ? (
          currentRank.name
        ) : (
          <span style={{ color: "red" }}>Цол сонгоогүй</span>
        );
      },
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => a.currentRank - b.currentRank,
    },
    {
      title: "Спортын зэрэг авсан огноо",
      dataIndex: "rateDate2",
      key: "rateDate2",
      render: (value) => {
        return dayjs(value).format("YYYY-MM-DD");
      },
    },
    {
      title: "Баг",
      dataIndex: "teamId",
      key: "teamId",
      render: (value, record) => {
        const currentTeam = teams[value];

        return currentTeam ? (
          currentTeam.name
        ) : (
          <span style={{ color: "red" }}>Баг сонгоогүй</span>
        );
      },
    },
    {
      title: "Имэйл хаяг",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Утасны дугаар",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Үйлдэл",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/archers/${record.key}`} key={record.key}>
            Засах
          </Link>
          {/* <Popconfirm
            title="Устгахдаа итгэлтэй байна уу?"
            onConfirm={() => deleteArcher(record)}
            okText="Тийм"
            cancelText="Үгүй"
          >
            <Button type="link">Устгах</Button>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];

  return (
    <>
      <PageTitle
        title="Харваачид"
        description="Харваачдын эрэмбэ"
        right={
          userData.role === "admin" ? (
            <> </>
          ) : (
            <>
              <Button
                shape="round"
                onClick={() => setVisible(true)}
                icon={<PlusOutlined />}
                size="large"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Харваач нэмэх
              </Button>
            </>
          )
        }
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <Form>
                        <Form.Item
                          name="gender"
                          label="Хүйс сонгох"
                          style={{ width: 200 }}
                        >
                          <Select onChange={(gender) => setGender(gender)}>
                            <Option value="male">Эрэгтэй</Option>
                            <Option value="female">Эмэгтэй</Option>
                          </Select>
                        </Form.Item>
                      </Form>
                      <Form
                        layout="inline"
                        style={{ marginBottom: 10 }}
                        form={searchForm}
                        initialValues={{ type: searchKey }}
                      >
                        <Form.Item name="type" label="Талбар">
                          <Select
                            value={searchKey}
                            style={{ width: 150 }}
                            onChange={(val) => {
                              setSearchKey(val);
                              setSearchValue(null);
                            }}
                            allowClear
                          >
                            <Option value="lastName">Овогоор</Option>
                            <Option value="firstName">Нэрээр</Option>
                            <Option value="email">Имэйл хаягаар</Option>
                            <Option value="rankId">Цолоор</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="name"
                          label="Хайх утга"
                          rules={[
                            {
                              required: true,
                              message: "Хайх утга оруулна уу!",
                            },
                          ]}
                        >
                          {searchKey === "rankId" ? (
                            <Select
                              placeholder="Цол"
                              style={{ width: 200 }}
                              onChange={(val) => setSearchValue(val)}
                              allowClear
                            >
                              {Object.values(ranks)
                                .sort((a: any, b: any) => a.order - b.order)
                                .map((rank: any) => (
                                  <Option key={rank.id} value={rank.id}>
                                    {rank.name}
                                  </Option>
                                ))}
                            </Select>
                          ) : (
                            <Input
                              onChange={(e: any) =>
                                setSearchValue(e.target.value)
                              }
                              defaultValue={searchValue}
                              value={searchValue}
                            />
                          )}
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button
                            type="primary"
                            onClick={() => onFinish()}
                            loading={loading}
                          >
                            Хайх
                          </Button>
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button
                            type="dashed"
                            onClick={() => clearSearchForm()}
                          >
                            Арилгах
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Table
                      columns={columns}
                      dataSource={searchGender === "male" ? menList : womenList}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Erembe;
