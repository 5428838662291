import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyASMoIEJ-7sAJhQzI_hQaOwW6YBUojwQYQ",
  authDomain: "national-archery.firebaseapp.com",
  projectId: "national-archery",
  storageBucket: "national-archery.appspot.com",
  messagingSenderId: "1058071199098",
  appId: "1:1058071199098:web:6895235c5edc8947f36ac3",
  measurementId: "G-B28ZKJEP3S",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err: any) {
    alert(err.message);
  }
};

const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export { auth, db, logInWithEmailAndPassword, sendPasswordReset, logout };
