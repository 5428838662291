import React, { useState, useEffect } from "react";
import { PageTitle } from "../../components";
import {
  Table,
  Space,
  Button,
  Form,
  Input,
  Select,
  Modal,
  Tabs,
  message,
  Skeleton,
  Checkbox,
} from "antd";
import { db } from "../../firebase";
import {
  collection,
  doc,
  onSnapshot,
  getDocs,
  query,
  where,
  updateDoc,
  collectionGroup,
} from "firebase/firestore";
import dayjs from "dayjs";
import { Routes, Route, useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

const { Option } = Select;
const { TabPane } = Tabs;

interface DataType {
  key?: string;
  name?: string;
}

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

function Rank() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [data, setData] = useState<DataType>({});
  const [loading, setLoading] = useState(true);

  let { rankId } = useParams();

  useEffect(() => {
    if (rankId) {
      const unsub = onSnapshot(doc(db, "ranks", rankId), (doc: any) => {
        setData(doc.data());
        form.setFieldsValue(doc.data());
        setLoading(false);
      });

      return unsub;
    }
  }, [rankId]);

  const getRanks = async () => {
    const querySnapshot = await getDocs(collection(db, "ranks"));
    const fetchedData: any = {};

    querySnapshot.forEach((doc) => {
      fetchedData[doc.id] = {
        id: doc.id,
        ...doc.data(),
      };
    });

    return fetchedData;
  };

  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        if (rankId) {
          const users = query(
            collectionGroup(db, "users"),
            where("rankId", "==", rankId)
          );

          const querySnapshot = await getDocs(users);
          querySnapshot.forEach((userDoc) => {
            if (rankId) {
              updateDoc(
                doc(db, "users", userDoc.id, "rank", rankId),
                values
              ).then(() => {
                console.log("User updated");
              });
            }
          });

          updateDoc(doc(db, "ranks", rankId), values).then(() => {
            message.success("Амжилттай.");
          });

          const ranks = await getRanks();
          dispatch({ type: "SET_RANKS", payload: ranks });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      {data ? (
        <PageTitle
          loading={loading}
          title={`${data?.name}`}
          right={null}
          breadcrumbItems={[
            { link: "/ranks", title: "Цол" },
            { title: `${data?.name} ` },
          ]}
        />
      ) : (
        ""
      )}
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <Skeleton loading={loading} active>
                    <Form
                      {...formItemLayout}
                      form={form}
                      onFinish={onFinish}
                      initialValues={{
                        isRank: false,
                      }}
                    >
                      <Form.Item
                        label="Нэр"
                        name="name"
                        rules={[{ required: true, message: "Нэр оруулна уу!" }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Дугаар"
                        name="order"
                        rules={[
                          { required: true, message: "Дугаар оруулна уу!" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Min point"
                        name="minPoint"
                        rules={[
                          { required: true, message: "Min point оруулна уу!" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Max point"
                        name="maxPoint"
                        rules={[
                          { required: true, message: "Max point оруулна уу!" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Цолны чимэг эсэх"
                        name="isRank"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                      <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                          Хадгалах
                        </Button>
                      </Form.Item>
                    </Form>
                  </Skeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Rank;
