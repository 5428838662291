import React, { useState, useEffect } from "react";
import { PageTitle } from "../../components";
import {
  Table,
  Space,
  Button,
  Form,
  Input,
  Select,
  Modal,
  Tabs,
  message,
  Skeleton,
} from "antd";
import { db } from "../../firebase";
import {
  collection,
  doc,
  onSnapshot,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import type { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";

import { Routes, Route, useParams, Link } from "react-router-dom";

const { Option } = Select;
const { TabPane } = Tabs;

interface DataType {
  key?: string;
  lastName?: string;
  firstName?: string;
}

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

function Referee() {
  const [form] = Form.useForm();

  const [data, setData] = useState<DataType>({});
  const [loading, setLoading] = useState(true);

  let { refereeId } = useParams();

  useEffect(() => {
    if (refereeId) {
      const unsub = onSnapshot(doc(db, "users", refereeId), (doc: any) => {
        setData(doc.data());
        form.setFieldsValue(doc.data());
        setLoading(false);
      });

      return unsub;
    }
  }, [refereeId]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (refereeId) {
          updateDoc(doc(db, "users", refereeId), values).then(() => {
            message.success("Амжилттай.");
          });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      {data ? (
        <PageTitle
          loading={loading}
          title={`${data?.lastName} ${data?.firstName}`}
          description={"Шүүгчийн профайл"}
          right={null}
          breadcrumbItems={[
            { link: "/referees", title: "Шүүгчид" },
            { title: `${data?.lastName} ${data?.firstName}` },
          ]}
        />
      ) : (
        ""
      )}
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <Skeleton loading={loading} active>
                    <Form {...formItemLayout} form={form} onFinish={onFinish}>
                      <Form.Item
                        label="Имэйл хаяг"
                        name="email"
                        rules={[
                          { required: true, message: "Имэйл хаяг оруулна уу!" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Овог"
                        name="lastName"
                        rules={[
                          { required: true, message: "Овог оруулна уу!" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Нэр"
                        name="firstName"
                        rules={[{ required: true, message: "Нэр оруулна уу!" }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Утасны дугаар"
                        name="phoneNumber"
                        rules={[
                          {
                            required: true,
                            message: "Утасны дугаар оруулна уу!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                          Хадгалах
                        </Button>
                      </Form.Item>
                    </Form>
                  </Skeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Referee;
