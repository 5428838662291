import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
const storage = getStorage();

const firebaseUpload =
  ({ onSuccess, onProgress, onError, file, path }: any) =>
  (dispatch: any) => {
    const storageRef = ref(storage, `${path}/${file.name}`);

    const task = uploadBytesResumable(storageRef, file);

    task.on(
      "state_changed",
      function progress(snapshot) {
        dispatch({ type: "FIREBASE_UPLOAD_PROGRESS" });
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        onProgress(
          {
            percent: progress,
          },
          file
        );
      },

      function error(err: any) {
        dispatch({ type: "FIREBASE_UPLOAD_ERROR", payload: err });
        onError(err, file);
      },

      function complete() {
        getDownloadURL(task.snapshot.ref).then((fileUrl: string) => {
          dispatch({ type: "FIREBASE_UPLOAD_END", payload: fileUrl });
          onSuccess(fileUrl, file);
        });
      }
    );
  };

export { firebaseUpload };
