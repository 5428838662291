import { REHYDRATE } from "redux-persist";
const initialState = {
  ranks: {},
  teams: {},
};

export function content(state = initialState, action: any) {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload) {
        return {
          ...state,
          ranks: action.payload.content.ranks
            ? action.payload.content.ranks
            : {},
          teams: action.payload.content.teams
            ? action.payload.content.teams
            : {},
        };
      } else {
        return {
          ...state,
        };
      }
    case "SET_RANKS": {
      return {
        ...state,
        ranks: action.payload,
      };
    }
    case "SET_TEAMS": {
      return {
        ...state,
        teams: action.payload,
      };
    }
    case "LOG_OUT": {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
