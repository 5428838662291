import React, { useState, useEffect } from "react";
import { PageTitle } from "../../components";
import {
  Table,
  Space,
  Button,
  Form,
  Input,
  Modal,
  DatePicker,
  message,
  InputNumber,
  Select,
  Checkbox
} from "antd";
import { db } from "../../firebase";
import {
  collection,
  addDoc,
  onSnapshot,
  getDocs,
  doc,
  where,
  query,
  Timestamp
} from "firebase/firestore";
import type { ColumnsType } from "antd/lib/table";
import { Link, Outlet } from "react-router-dom";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import { checkOnlyNumbers } from "../../_helpers/utils";
import { useSelector } from "react-redux";

const { Option } = Select;

interface DataType {
  key: string;
  name: string;
  date: string;
}

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 }
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 18 }
};

const columns: ColumnsType<DataType> = [
  {
    title: "№",
    dataIndex: "id",
    key: "id",
    render: (text, row, index) => <>{index + 1}</>
  },
  {
    title: "Нэр",
    dataIndex: "name",
    key: "name",
    render: (item, record) => (
      <Space size="middle">
        <Link to={`/competitions/${record.key}`} key={record.key}>
          {item}
        </Link>
      </Space>
    )
  },
  {
    title: "Өдөр",
    dataIndex: "startDate",
    key: "startDate",
    render: (item) => {
      const date = new Date(item.seconds * 1000);
      return <span>{dayjs(date).format("YYYY-MM-DD")}</span>;
    }
  },
  {
    title: "Төлөв",
    dataIndex: "status",
    key: "status",
    render: (item) => {
      if (item === "open") return "Нээлттэй";
      return <span>Дууссан</span>;
    }
  }
];

function Competitions() {
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();
  const [referees, setReferees] = useState<any[]>([]);
  const [data, setData] = useState<DataType[]>([]);
  const [oldData, setOldData] = useState<DataType[]>([]);
  const [selectedRefs, setSelectedRefs] = useState<any[]>([]);
  const [searchKey, setSearchKey] = useState("displayName");
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState(null);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const userData = useSelector((state: any) => state.authentication.userData);

  useEffect(() => {
    getDocs(
      query(collection(db, "users"), where("role", "==", "referee"))
    ).then((querySnapshot) => {
      const refereesArr: any[] = [];

      querySnapshot.forEach((doc: any) => {
        refereesArr.push({ ...doc.data(), key: doc.id });
      });

      setReferees(refereesArr);
    });
  }, []);

  useEffect(() => {
    const unsub = onSnapshot(
      collection(db, "competitions"),
      (querySnapshot) => {
        const comps: DataType[] = [];

        querySnapshot.forEach((doc: any) => {
          comps.push({ ...doc.data(), key: doc.id });
        });
        setLoading(false);
        setData(comps);
        setOldData(comps);
      }
    );

    return unsub;
  }, []);

  const onFinish = () => {
    setLoading(true);
    getDocs(
      query(collection(db, "competitions"), where("name", "==", searchValue))
    ).then((querySnapshot) => {
      const comps: DataType[] = [];

      querySnapshot.forEach((doc: any) => {
        comps.push({ ...doc.data(), key: doc.id });
      });
      setLoading(false);
      setData(comps);
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const zurhai = values.referees.length;
        if (!checkOnlyNumbers(zurhai)) {
          alert("Зурхайн тоо буруу байна");
          return;
        }
        if (!checkOnlyNumbers(values.fee)) {
          alert("Төлбөрийн хэмжээ буруу байна");
          return;
        }
        setConfirmLoading(true);
        const startDate = new Date(values.startDate);
        const startTimestamp = Timestamp.fromDate(startDate);
        values.startDate = startTimestamp;

        const endDate = new Date(values.endDate);
        const endTimestamp = Timestamp.fromDate(endDate);
        values.endDate = endTimestamp;

        const endRegisterDate = new Date(values.endRegisterDate);
        const endRegisterTimestamp = Timestamp.fromDate(endRegisterDate);
        values.endRegisterDate = endRegisterTimestamp;

        const refereeObj: any[] = [];
        values.referees.map((referee: any, index: number) => {
          const currentRef: any = Object.values(referees).find(
            (elem: any) => elem.key === referee.refereeId
          );

          currentRef.zurhai = index;

          refereeObj.push(currentRef);
        });

        const mainReferee = referees.filter(
          (referee) => referee.key === values.referee
        );

        addDoc(collection(db, "competitions"), {
          ...values,
          referee: mainReferee[0],
          zurhai,
          referees: refereeObj,
          status: "open"
        }).then((response) => {
          setConfirmLoading(false);
          setVisible(false);
          message.success(`"${values.name}" тэмцээн амжилттай үүслээ.`);
        });
      })
      .catch((info) => {
        setConfirmLoading(false);
        // form.resetFields();
        console.log("Validate Failed:", info);
      });
  };

  const clearSearchForm = () => {
    searchForm.resetFields();
    setSearchValue(null);
    setData(oldData);
  };

  const onCompFinish = () => {
    alert("Finish");
  };

  function hasDuplicates(data: any) {
    const refereeIds = new Set();

    for (let i = 0; i < data.length; i++) {
      const item = data[i];

      // Check if the current item has a refereeId property
      if (item?.refereeId) {
        const refereeId = item.refereeId;

        // If the refereeId is already in the Set, it's a duplicate
        if (refereeIds.has(refereeId)) {
          return true;
        }

        // Add the refereeId to the Set
        refereeIds.add(refereeId);
      }
    }

    // No duplicates found
    return false;
  }

  const checkRefs = (_: any, value: any) => {
    if (!hasDuplicates(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("Нэг шүүгчийг олон зурхай дээр сонгох боломжгүй!")
    );
  };

  return (
    <>
      <PageTitle
        title="Тэмцээнүүд"
        description="Тэмцээний жагсаалт"
        right={
          // userData.role === "leader" ? (
          //   <> </>
          // ) : (
          //   <>
          <Button
            shape="round"
            onClick={() => setVisible(true)}
            icon={<PlusOutlined />}
            size="large"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            Тэмцээн нэмэх
          </Button>
          // </>
          // )
        }
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-8">
                      <Form
                        form={searchForm}
                        layout="inline"
                        style={{ marginBottom: 10 }}
                        initialValues={{ type: searchKey }}
                      >
                        <Form.Item name="name" label="Тэмцээний нэр">
                          <Input
                            onChange={(e: any) =>
                              setSearchValue(e.target.value)
                            }
                          />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button type="primary" onClick={() => onFinish()}>
                            Хайх
                          </Button>
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button
                            type="dashed"
                            onClick={() => clearSearchForm()}
                          >
                            Арилгах
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Table
                      columns={columns}
                      dataSource={data}
                      loading={loading}
                      rowKey={(item) => item.key}
                    />
                  </div>

                  {/* <Pagination
                    current={currentPage}
                    totalPages={totalPages}
                    prevAction={prevPage}
                    nextAction={nextPage}
                    changeAction={(num) => changePage(num)}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Тэмцээн нэмэх"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Нэмэх"
        cancelText="Болих"
        width={720}
        confirmLoading={confirmLoading}
      >
        <Form
          {...formItemLayout}
          form={form}
          name="register_competition"
          initialValues={{
            detailLink: null,
            guidance: null,
            safety: null,
            referees: []
          }}
        >
          <Form.Item
            label="Улсын чанартай эсэх:"
            name="isRank"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label="Нэр"
            name="name"
            rules={[{ required: true, message: "Нэр оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Байршил"
            name="location"
            rules={[{ required: true, message: "Байршил оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Эхлэх огноо"
            name="startDate"
            rules={[{ required: true, message: "Эхлэх огноо сонгоно уу!" }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Дуусах огноо"
            name="endDate"
            rules={[{ required: true, message: "Дуусах огноо сонгоно уу!" }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Бүртгэл дуусах огноо"
            name="endRegisterDate"
            rules={[
              { required: true, message: "Бүртгэл дуусах огноо сонгоно уу!" }
            ]}
          >
            <DatePicker />
          </Form.Item>
          {/* <Form.Item
            label="Зурхайн тоо"
            name="zurhai"
            rules={[{ required: true, message: "Зурхайн тоо оруулна уу!" }]}
          >
            <InputNumber min={1} />
          </Form.Item> */}
          <Form.List
            name="referees"
            rules={[
              {
                validator: async (_, refers) => checkRefs(_, refers)
              }
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    noStyle
                    key={field.key}
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.zurhai !== curValues.zurhai
                    }
                  >
                    {() => (
                      <Space.Compact block>
                        <Form.Item
                          style={{ width: "100%" }}
                          label={`#${index + 1} зурхайн шүүгч:`}
                          name={[field.name, `refereeId`]}
                          rules={[
                            {
                              required: true,
                              message: "Зурхайн шүүгч сонгоно уу!"
                            }
                          ]}
                        >
                          <Select placeholder="Зурхайн шүүгч сонгох" allowClear>
                            {referees.map((referee) => (
                              <Option key={referee.key} value={referee.key}>
                                {referee.lastName} {referee.firstName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Space align="center" style={{ height: 30 }}>
                          <MinusCircleOutlined
                            onClick={() => remove(field.name)}
                          />
                        </Space>
                      </Space.Compact>
                    )}
                  </Form.Item>
                ))}

                <Form.Item
                  wrapperCol={{
                    span: 14,
                    offset: 6
                  }}
                  style={{ width: "100%" }}
                >
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Зурхай нэмэх
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item
            label="Ерөнхий шүүгч"
            name={"referee"}
            rules={[{ required: true, message: "Ерөнхий шүүгч оруулна уу!" }]}
          >
            <Select placeholder="Ерөнхий шүүгч сонгох" allowClear>
              {referees.map((referee) => (
                <Option key={referee.key} value={referee.key}>
                  {referee.lastName} {referee.firstName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Төлбөр"
            name="fee"
            rules={[{ required: true, message: "Төлбөр оруулна уу!" }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item label="Линк" name="detailLink">
            <Input />
          </Form.Item>
          <Form.Item label="Удирдамж" name="guidance">
            <Input />
          </Form.Item>
          <Form.Item label="Аюулгүй ажиллагаа" name="safety">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
export default Competitions;
