import { useState, useEffect } from "react";
import { PageTitle } from "../../components";
import {
  Table,
  Space,
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  message,
} from "antd";
import { db } from "../../firebase";
import {
  collection,
  addDoc,
  onSnapshot,
  deleteDoc,
  doc,
  orderBy,
  query,
} from "firebase/firestore";
import type { ColumnsType } from "antd/lib/table";
import { Link, useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { NewsProp } from "../../_helpers/type";
import { deleteObject, getStorage, ref } from "firebase/storage";

const storage = getStorage();
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const columns: ColumnsType<NewsProp> = [
  {
    title: "#",
    dataIndex: "listNumber",
    key: "listNumber",
    render: (value, record: any) => <Space size="middle">{value}</Space>,
  },
  {
    title: "Нэр",
    dataIndex: "title",
    key: "title",
    render: (value, record: any) => (
      <Space size="middle">
        <Link to={`/news/${record.id}`} id={record.id}>
          {value}
        </Link>
      </Space>
    ),
  },
  {
    title: "Товч",
    dataIndex: "description",
    key: "description",
    render: (value, record: any) => <Space size="middle">{value}</Space>,
  },
  {
    title: "Өдөр",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (value, record: any) => (
      <Space size="middle">
        {dayjs(value.toDate()).format("YYYY-MM-DD HH:mm")}
      </Space>
    ),
  },
  {
    title: "Үйлдэл",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <Popconfirm
          title="Устгахдаа итгэлтэй байна уу?"
          onConfirm={() => deleteNews(record)}
          okText="Тийм"
          cancelText="Үгүй"
        >
          <Button type="link">Устгах</Button>
        </Popconfirm>
      </Space>
    ),
  },
];

const deleteNews = async (news: any) => {
  if (news?.imageName) {
    const storageRef = ref(storage, `newsImage/${news.imageName}`);
    await deleteObject(storageRef);
  }

  await deleteDoc(doc(db, "news", news.id));
  message.success(`"${news.title}" - мэдээ амжилттай устгалаа.`);
};

function News() {
  const [form] = Form.useForm();
  let navigate = useNavigate();

  const [data, setData] = useState<NewsProp[]>([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<any>(10);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const q = query(collection(db, "news"), orderBy("createdAt", "desc"));
    const unsub = onSnapshot(q, (querySnapshot) => {
      const news: NewsProp[] = [];

      querySnapshot.forEach((doc: any) => {
        news.push({ ...doc.data(), id: doc.id });
      });

      setLoading(false);
      setData(news);
      setTotalItems(querySnapshot.size);
    });

    return unsub;
  }, []);

  const handlePaginationChange = (current: number, pageSize: number) => {
    setCurrentPage(current);
    setPageSize(pageSize);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    form
      .validateFields()
      .then((values) => {
        addDoc(collection(db, "ranks"), {
          ...values,
          createdAt: new Date(),
        }).then((response) => {
          form.resetFields();
          setConfirmLoading(false);
          setVisible(false);
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <PageTitle
        title="Мэдээ"
        description="Мэдээний жагсаалт"
        right={
          <Button
            shape="round"
            onClick={() => navigate("/add-news")}
            icon={<PlusOutlined />}
            size="large"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Мэдээ нэмэх
          </Button>
        }
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      columns={columns}
                      dataSource={data.map((item, index) => {
                        return {
                          ...item,
                          listNumber: (currentPage - 1) * pageSize + index + 1,
                        };
                      })}
                      loading={loading}
                      rowKey={(item) => item.id}
                      pagination={{
                        current: currentPage,
                        pageSize: pageSize,
                        total: totalItems,
                        onChange: handlePaginationChange,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Цол нэмэх"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Нэмэх"
        cancelText="Болих"
        width={720}
        confirmLoading={confirmLoading}
      >
        <Form {...formItemLayout} form={form}>
          <Form.Item
            label="Нэр"
            name="name"
            rules={[{ required: true, message: "Нэр оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Дугаар"
            name="order"
            rules={[{ required: true, message: "Дугаар оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Min point"
            name="minPoint"
            rules={[{ required: true, message: "Min point оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Max point"
            name="maxPoint"
            rules={[{ required: true, message: "Max point оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
export default News;
