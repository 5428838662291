import { combineReducers } from "redux";

import { alert } from "./alert.reducer";
import { authentication } from "./authentication.reducer";
import { content } from "./content.reducer";
import { firebaseUploderReducer } from "./firebaseUploader.reducer";

const rootReducer = combineReducers({
  alert,
  content,
  authentication,
  firebaseUploderReducer,
});

export default rootReducer;
