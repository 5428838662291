import React, { useState, useEffect } from "react";
import { PageTitle } from "../../components";
import {
  Table,
  Space,
  Button,
  Form,
  Input,
  Select,
  Modal,
  DatePicker,
  Radio,
  Popconfirm,
  message,
  Checkbox,
  notification
} from "antd";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  deleteDoc,
  doc,
  onSnapshot
} from "firebase/firestore";
import { PlusOutlined } from "@ant-design/icons";
import { db } from "../../firebase";
import { Link, Outlet } from "react-router-dom";
import dayjs from "dayjs";
import type { ColumnsType } from "antd/lib/table";
import { API } from "../../_helpers";
import { RankProp } from "../../_helpers/type";
import { useSelector } from "react-redux";
import datatest from "../../datatest.json";

interface DataType {
  key: string;
  lastName: string;
  firstName: string;
  date: string;
}

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 }
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 18 }
};

const deleteArcher = async (user: any) => {
  await deleteDoc(doc(db, "users", user.key));
  message.success(
    `"${user.lastName} ${user.firstName}" харваач амжилттай устгалаа.`
  );
};

const { Option } = Select;

function Archers() {
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();
  const [data, setData] = useState<DataType[]>([]);
  const [oldData, setOldData] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchKey, setSearchKey] = useState("firstName");
  const [searchValue, setSearchValue] = useState<any>(null);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const ranks = useSelector((state: any) => state.content.ranks);
  const teams = useSelector((state: any) => state.content.teams);
  const userData = useSelector((state: any) => state.authentication.userData);

  const checkJson = () => {
    const ner: any = [];
    datatest.map((item: any) => {
      const nerr = oldData.find(
        (elem: any) => elem.ssn.toLowerCase() === item.ssn.toLowerCase()
      );
      if (!nerr) {
        ner.push(item);
      }
      let currentRank: any = { id: "BhenrL9FHcZqJBuZdrqi" };
      let currentRank2: any = { id: "BhenrL9FHcZqJBuZdrqi" };
      if (item.rankId) {
        currentRank = Object.values(ranks).find(
          (elem: any) => elem.name.toLowerCase() === item.rankId.toLowerCase()
        );
      }
      const currentTeam: any = Object.values(teams).find(
        (elem: any) => elem.name.toLowerCase() === item.team.toLowerCase()
      );
      if (item.rate2) {
        currentRank2 = Object.values(ranks).find(
          (elem: any) => elem.name.toLowerCase() === item.rate2.toLowerCase()
        );
      }

      if (currentRank && currentTeam) {
        const font = item.ssn.slice(2, 10);
        const kjlk = {
          email: font + "@gmail.com",
          phoneNumber: "+976" + font,
          password: font.toString(),
          birthDate: null,
          cityProvince: "",
          districtTown: "",
          firstName: item.firstName,
          gender: item.gender,
          isHandicapped: item.isHandicapped === "FALSE",
          isOld: item.isOld === "FALSE",
          lastName: item.lastName ? item.lastName : "",
          rate: currentRank.id,
          rankId: currentRank.id,
          rateDate: item.rateDate ? dayjs(item.rateDate) : dayjs(),
          ratePoint: 0,
          ssn: item.ssn,
          team: currentTeam.id,
          isPaid: false,
          isTeamLeader: false,
          rate2: currentRank2.id,
          rateDate2: item.rateDate2 ? dayjs(item.rateDate2) : dayjs()
        };
        try {
          API.post("register", kjlk).then((result) => {
            if (result.status === 201) {
              notification["success"]({
                message: "Амжилттай",
                description: `${item.lastName} ${item.firstName} харваач амжилттай бүртгэгдлээ.`
              });
            } else {
              if (result.data.error.code === "invalid-email") {
                console.log("---", item);
              }
              notification["error"]({
                message: "Амжилтгүй",
                description: result.data.error.toString()
              });
            }
          });
        } catch (e) {
          console.log(e);
        }
      } else {
        console.log("+++", item);
      }
    });
    console.log("ner", ner);
  };

  useEffect(() => {
    const unsub = onSnapshot(
      query(collection(db, "users")),
      (querySnapshot) => {
        const comps: DataType[] = [];

        querySnapshot.forEach((doc: any) => {
          const docData = doc.data();

          if (docData?.role !== "archer") return;
          comps.push({ ...docData, key: doc.id });
        });
        comps.sort((a: any, b: any) =>
          a?.firstName?.localeCompare(b?.firstName)
        );

        setLoading(false);
        setData(comps);
        setOldData(comps);
      }
    );

    return unsub;
  }, []);

  const onFinish = () => {
    setLoading(true);

    const comps: DataType[] = oldData.filter((item: any): boolean =>
      item[searchKey]?.toLowerCase().includes(searchValue?.toLowerCase())
    );

    setLoading(false);
    setData(comps);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setConfirmLoading(true);
        try {
          API.post("register", {
            email: values.email,
            phoneNumber: "+976" + values.phoneNumber,
            password: values.phoneNumber,
            birthDate: null,
            cityProvince: "",
            districtTown: "",
            // cityProvince: values.cityProvince,
            // districtTown: values.districtTown,
            firstName: values.firstName,
            gender: values.gender,
            isHandicapped: values.isHandicapped,
            isOld: values.isOld,
            lastName: values.lastName,
            rate: values.rankId,
            rate2: values.rankId,
            rankId: values.rankId,
            rateDate: values.rateDate,
            rateDate2: values.rateDate,
            ratePoint: 0,
            ssn: values.ssn,
            team: values.teamId,
            isTeamLeader: values.isTeamLeader,
            isPaid: false,
            role: "archer"
          }).then((result) => {
            if (result.status === 201) {
              notification["success"]({
                message: "Амжилттай",
                description: `${values.lastName} ${values.firstName} харваач амжилттай бүртгэгдлээ.`
              });
              setVisible(false);
              setConfirmLoading(false);
              form.resetFields();
            } else {
              setConfirmLoading(false);
              console.log(result);
              // notification["error"]({
              //   message: "Амжилтгүй",
              //   description: result.data.error.toString()
              // });
            }
          });
        } catch (e) {
          console.log(e);
        }
      })
      .catch((info) => {
        setConfirmLoading(false);
        console.log("Validate Failed:", info);
      });
  };

  const clearSearchForm = () => {
    searchForm.resetFields();
    setSearchValue(null);
    setData(oldData);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => <>{index + 1}</>
    },
    {
      title: "Овог",
      dataIndex: "lastName",
      key: "lastName"
    },
    {
      title: "Нэр",
      dataIndex: "firstName",
      key: "firstName"
    },
    {
      title: "Регистрийн дугаар",
      dataIndex: "ssn",
      key: "ssn"
    },
    {
      title: "Улсын цол",
      dataIndex: "rankId",
      key: "rankId",
      render: (value, record) => {
        const currentRank = ranks[value];

        return currentRank ? (
          currentRank.name
        ) : (
          <span style={{ color: "red" }}>Цол сонгоогүй</span>
        );
      },
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => a.currentRank - b.currentRank
    },
    {
      title: "Улсын цол авсан огноо",
      dataIndex: "rateDate",
      key: "rateDate",
      render: (value) => {
        return dayjs(value).format("YYYY-MM-DD");
      }
    },
    {
      title: "Спортын зэрэг",
      dataIndex: "rate2",
      key: "rate2",
      render: (value, record) => {
        const currentRank = ranks[value];

        return currentRank ? (
          currentRank.name
        ) : (
          <span style={{ color: "red" }}>Цол сонгоогүй</span>
        );
      },
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => a.currentRank - b.currentRank
    },
    {
      title: "Спортын зэрэг авсан огноо",
      dataIndex: "rateDate2",
      key: "rateDate2",
      render: (value) => {
        return dayjs(value).format("YYYY-MM-DD");
      }
    },
    {
      title: "Баг",
      dataIndex: "teamId",
      key: "teamId",
      render: (value, record) => {
        const currentTeam = teams[value];

        return currentTeam ? (
          currentTeam.name
        ) : (
          <span style={{ color: "red" }}>Баг сонгоогүй</span>
        );
      }
    },
    {
      title: "Имэйл хаяг",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Утасны дугаар",
      dataIndex: "phoneNumber",
      key: "phoneNumber"
    },
    {
      title: "Үйлдэл",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/archers/${record.key}`} key={record.key}>
            Засах
          </Link>
          <Popconfirm
            title="Устгахдаа итгэлтэй байна уу?"
            onConfirm={() => deleteArcher(record)}
            okText="Тийм"
            cancelText="Үгүй"
          >
            <Button type="link">Устгах</Button>
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (
    <>
      <PageTitle
        title="Харваачид"
        description="Харваачдын жагсаалт"
        right={
          userData.role !== "admin" ? (
            <> </>
          ) : (
            <>
              <Button
                shape="round"
                onClick={() => setVisible(true)}
                icon={<PlusOutlined />}
                size="large"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                Харваач нэмэх
              </Button>
              <Button
                shape="round"
                onClick={() => checkJson()}
                icon={<PlusOutlined />}
                size="large"
              >
                Импорт {oldData.length}
              </Button>
            </>
          )
        }
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <Form
                        layout="inline"
                        style={{ marginBottom: 10 }}
                        form={searchForm}
                        initialValues={{ type: searchKey }}
                      >
                        <Form.Item name="type" label="Талбар">
                          <Select
                            value={searchKey}
                            style={{ width: 300 }}
                            onChange={(val) => {
                              setSearchKey(val);
                              setSearchValue(null);
                            }}
                            allowClear
                          >
                            <Option value="lastName">Овогоор</Option>
                            <Option value="firstName">Нэрээр</Option>
                            <Option value="email">Имэйл хаягаар</Option>
                            <Option value="rankId">Цолоор</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="name"
                          label="Хайх утга"
                          rules={[
                            {
                              required: true,
                              message: "Хайх утга оруулна уу!"
                            }
                          ]}
                        >
                          {searchKey === "rankId" ? (
                            <Select
                              placeholder="Цол"
                              style={{ width: 200 }}
                              onChange={(val) => setSearchValue(val)}
                              allowClear
                            >
                              {Object.values(ranks)
                                .sort((a: any, b: any) => a.order - b.order)
                                .map((rank: any) => (
                                  <Option key={rank.id} value={rank.id}>
                                    {rank.name}
                                  </Option>
                                ))}
                            </Select>
                          ) : (
                            <Input
                              onChange={(e: any) =>
                                setSearchValue(e.target.value)
                              }
                              defaultValue={searchValue}
                              value={searchValue}
                            />
                          )}
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button
                            type="primary"
                            onClick={() => onFinish()}
                            loading={loading}
                          >
                            Хайх
                          </Button>
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button
                            type="dashed"
                            onClick={() => clearSearchForm()}
                          >
                            Арилгах
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Table
                      columns={columns}
                      dataSource={data}
                      loading={loading}
                    />
                  </div>

                  {/* <Pagination
                    current={currentPage}
                    totalPages={totalPages}
                    prevAction={prevPage}
                    nextAction={nextPage}
                    changeAction={(num) => changePage(num)}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Харваач нэмэх"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Нэмэх"
        cancelText="Болих"
        width={720}
        confirmLoading={confirmLoading}
      >
        <Form
          {...formItemLayout}
          form={form}
          initialValues={{
            isHandicapped: false,
            isOld: false,
            isTeamLeader: false
          }}
        >
          {/* <Form.Item
            label="Хот/Аймаг"
            name="cityProvince"
            rules={[{ required: true, message: "Хот/Аймаг сонгоно уу!" }]}
          >
            <Select placeholder="Хот/Аймаг" allowClear>
              <Option value="ulaanbaatar">Улаанбаатар</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Дүүрэг/Сум"
            name="districtTown"
            rules={[{ required: true, message: "Дүүрэг/Сум сонгоно уу!" }]}
          >
            <Select placeholder="Дүүрэг/Сум" allowClear>
              <Option value="jargaltkhaan">Жаргалт хаан</Option>
            </Select>
          </Form.Item> */}
          <Form.Item
            label="Имэйл хаяг"
            name="email"
            rules={[{ required: true, message: "Имэйл хаяг оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Утасны дугаар"
            name="phoneNumber"
            rules={[{ required: true, message: "Утасны дугаар оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Овог"
            name="lastName"
            rules={[{ required: true, message: "Овог оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Нэр"
            name="firstName"
            rules={[{ required: true, message: "Нэр оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Хүйс" name="gender">
            <Radio.Group value="male">
              <Radio.Button value="male">Эрэгтэй</Radio.Button>
              <Radio.Button value="female">Эмэгтэй</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Төрсөн огноо"
            name="birthDate"
            rules={[{ required: false, message: "Төрсөн огноо сонгоно уу!" }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Регистрийн дугаар"
            name="ssn"
            rules={[
              { required: true, message: "Регистрийн дугаар оруулна уу!" }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Баг"
            name="teamId"
            rules={[{ required: true, message: "Баг сонгоно уу!" }]}
          >
            <Select placeholder="Баг" allowClear>
              {Object.values(teams)
                .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
                .map((team: any) => (
                  <Option key={team.id} value={team.id}>
                    {team.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Улсын цол"
            name="rankId"
            rules={[{ required: true, message: "Цол сонгоно уу!" }]}
          >
            <Select placeholder="Цол" allowClear>
              {Object.values(ranks)
                .filter((rank: any) => !rank.isRank)
                .sort((a: any, b: any) =>
                  parseInt(a.order) > parseInt(b.order) ? 1 : -1
                )
                .map((rank: any) => (
                  <Option key={rank.id} value={rank.id}>
                    {rank.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Улсын цол авсан огноо"
            name="rateDate"
            rules={[{ required: true, message: "Цол авсан огноо сонгоно уу!" }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Спортын зэрэг"
            name="rate2"
            rules={[{ required: false, message: "Цол сонгоно уу!" }]}
          >
            <Select placeholder="Спортын зэрэг" allowClear>
              {Object.values(ranks)
                .filter((rank: any) => rank.isRank)
                .sort((a: any, b: any) =>
                  parseInt(a.order) > parseInt(b.order) ? 1 : -1
                )
                .map((rank: any) => (
                  <Option key={rank.id} value={rank.id}>
                    {rank.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Спортын зэрэг авсан огноо"
            name="rateDate2"
            rules={[
              {
                required: false,
                message: "Спортын зэрэг авсан огноо сонгоно уу!"
              }
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Хөгжлийн бэрхшээлтэй эсэх"
            name="isHandicapped"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item label="Ахмад харваач" name="isOld" valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <Form.Item
            label="Багийн ахлагч"
            name="isTeamLeader"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
export default Archers;
