import React, { useEffect, useState, useRef, useCallback } from "react";
import dayjs from "dayjs";
import Zurhai from "./Zurhai";
import "./board.scss";
import { Button, Space } from "antd";
import { toJpeg } from "html-to-image";
import { jsPDF } from "jspdf";
import { orderArchers } from "../../_helpers/utils";

const htmlStringToPdf = (data: any, title: string) => {
  const doc = new jsPDF({
    format: "a4",
    unit: "px"
  });

  doc.addImage(data, "JPEG", 20, 20, 400, 0);
  doc.save(title);
};

const Board = ({ competition, competitors, zurhaiTotal, referees }: any) => {
  const [menList, setMenList] = useState([]);
  const [womenList, setWomenList] = useState([]);
  const [lastList, setLastList] = useState([]);
  const [zurhai, setZurhai] = useState(0);
  const [levelNum, setLevelNum] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const zogsool_dugaar = 4;
  const fillofzogsool = zurhaiTotal * zogsool_dugaar;

  const onButtonClick = useCallback(async () => {
    if (ref.current === null) {
      return;
    }

    toJpeg(ref.current, {
      cacheBust: true,
      backgroundColor: "#fff",
      pixelRatio: 1
    })
      .then((dataUrl) => {
        htmlStringToPdf(dataUrl, `Zurhai-${zurhai + 1}`);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);

  useEffect(() => {
    if (competitors && competitors.length > 0) {
      let men = competitors.filter((archer: any) => archer.gender === "male");
      men = orderArchers(men);
      let women = competitors.filter(
        (archer: any) => archer.gender === "female"
      );
      women = orderArchers(women);

      const lastmen = men.slice(men.length - fillofzogsool, men.length);
      men = men.slice(0, men.length - fillofzogsool);
      const men_round =
        men.length % fillofzogsool === 0
          ? men.length / fillofzogsool
          : Math.floor(men.length / fillofzogsool) + 1;
      const women_round =
        women.length % fillofzogsool === 0
          ? women.length / fillofzogsool
          : Math.floor(women.length / fillofzogsool) + 1;
      const last_round = 1;
      const uy_number = men_round + women_round + last_round;

      // console.log(
      //   "com",
      //   competitors.length,
      //   "men",
      //   men.length,
      //   "women",
      //   women.length,
      //   "last",
      //   lastmen.length,
      //   "after niit",
      //   men.length + women.length + lastmen.length
      // );
      setLevelNum(uy_number);

      let testmen: any = [];
      for (let i = zurhai; i < men.length; i = i + zurhaiTotal) {
        testmen.push(men[i]);
      }
      let testwomen: any = [];
      for (let i = zurhai; i < women.length; i = i + zurhaiTotal) {
        testwomen.push(women[i]);
      }
      let testlastmen: any = [];
      for (let i = zurhai; i < lastmen.length; i = i + zurhaiTotal) {
        testlastmen.push(lastmen[i]);
      }

      setMenList(testmen);
      setWomenList(testwomen);
      setLastList(testlastmen);
      // console.log(
      //   "meedku",
      //   testmen.length + testwomen.length + testlastmen.length
      // );
    }
  }, [competitors, zurhai]);

  const changeZurhai = (type: number) => {
    if (type === 0) {
      setZurhai(zurhai - 1);
    } else if (type === 1) {
      setZurhai(zurhai + 1);
    }
  };

  return (
    <div className="zurhai-container">
      <div className="zurhai-header">
        <div className="btn-navs">
          <Space>
            <Button onClick={() => changeZurhai(0)} disabled={zurhai === 0}>
              Өмнөх
            </Button>
            {zurhai + 1}
            <Button
              onClick={() => changeZurhai(1)}
              disabled={zurhai === zurhaiTotal - 1}
            >
              Дараа
            </Button>
          </Space>
        </div>
        <Button onClick={onButtonClick}>Татаж авах</Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 50
        }}
      >
        <Zurhai
          index={zurhai}
          competition={competition}
          referees={referees}
          zurhai={fillofzogsool}
          men={menList}
          women={womenList}
          lastmen={lastList}
          uy_number={levelNum}
          ref={ref}
        />
      </div>
    </div>
  );
};

export default Board;
