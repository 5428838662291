import {
  Home,
  Competitions,
  Teams,
  Archers,
  Referees,
  Ranks,
  News,
  Leaders,
  Ranking,
  Erembe,
  Privacy
} from "../pages";

export const routes = [
  {
    path: "/",
    key: "home",
    component: <Home />,
    label: "Нүүр",
    showHeader: true,
    icon: "profile"
  },
  {
    path: "/competitions",
    key: "competitions",
    component: <Competitions />,
    label: "Тэмцээнүүд",
    showHeader: true,
    icon: "profile"
  },
  {
    path: "/teams",
    key: "teams",
    component: <Teams />,
    label: "Багууд",
    showHeader: true,
    icon: "profile"
  },
  {
    path: "/referees",
    key: "referees",
    component: <Referees />,
    label: "Шүүгчид",
    showHeader: true,
    icon: "profile"
  },
  {
    path: "/leaders",
    key: "leaders",
    component: <Leaders />,
    label: "Багийн ахлагчид",
    showHeader: true,
    icon: "profile"
  },
  {
    path: "/archers",
    key: "archers",
    component: <Archers />,
    label: "Харваачид",
    showHeader: true,
    icon: "profile"
  },
  {
    path: "/ranks",
    key: "ranks",
    component: <Ranks />,
    label: "Цол",
    showHeader: true,
    icon: "profile"
  },
  {
    path: "/news",
    key: "news",
    component: <News />,
    label: "Мэдээ",
    showHeader: true,
    icon: "profile"
  },
  {
    path: "/rankings",
    key: "rankings",
    component: <Ranking />,
    label: "Чансаа",
    showHeader: true,
    icon: "profile"
  },
  {
    path: "/erembe",
    key: "erembe",
    component: <Erembe />,
    label: "Эрэмбэ",
    showHeader: true,
    icon: "profile"
  },
  {
    path: "/privacy-policy",
    key: "privacy",
    component: <Privacy />,
    label: "Тохиргоо",
    showHeader: true,
    icon: "profile"
  }
];
