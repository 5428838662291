import React, { useState, useEffect } from "react";
import { PageTitle } from "../../components";
import {
  Table,
  Space,
  Button,
  Form,
  Input,
  Select,
  Modal,
  Tabs,
  notification
} from "antd";
import { db } from "../../firebase";
import {
  collection,
  doc,
  onSnapshot,
  getDocs,
  query,
  where,
  updateDoc,
  setDoc,
  addDoc,
  deleteDoc
} from "firebase/firestore";
import type { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { PlusOutlined } from "@ant-design/icons";
import { Routes, Route, useParams, useRouteLoaderData } from "react-router-dom";
import Archers from "./Archers";
import PointTable from "./PointTable";
import Board from "./Board";
import { ArcheryProp } from "../../_helpers/type";
import ByTeam from "./ByTeam";
import { userActions } from "../../_actions";
import { useSelector } from "react-redux";

const { Option } = Select;
const { TabPane } = Tabs;

interface DataType {
  key?: string;
  name?: string;
  date?: string;
}

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 }
};

function Competition() {
  const [form] = Form.useForm();
  const [searchTeacher] = Form.useForm();
  const [data, setData] = useState<any>({});
  const [teams, setTeams] = useState<any[]>([]);
  const [searchVal, setSearchVal] = useState<string | null>(null);
  const [archersOptions, setArchersOptions] = useState<any[]>([]);
  const [competitors, setCompetitors] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [isFinishedLoading, setIsFinishedLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [totalArcher, setTotalArcher] = useState<number>(0);
  const [ranks, setRanks] = useState<any>([]);
  const [archerGender, setArcherGender] = useState<string>("all");
  const userData = useSelector((state: any) => state.authentication.userData);
  let { competitionId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "ranks"));
      const fetchedData: any = [];
      querySnapshot.forEach((doc) => {
        fetchedData.push({
          id: doc.id,
          ...doc.data()
        });
      });
      setRanks(fetchedData);
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (competitionId) {
      const unsub = onSnapshot(
        collection(db, "competitions", competitionId, "archers"),
        (querySnapshot) => {
          const fetchedData: any = [];
          querySnapshot.forEach((doc) => {
            fetchedData.push({
              id: doc.id,
              ...doc.data()
            });
          });
          setCompetitors(fetchedData);
        }
      );

      return unsub;
    }
  }, [competitionId]);

  useEffect(() => {
    const fetchData2 = async () => {
      const querySnapshot2 = await getDocs(collection(db, "ranks"));
      const fetchedData2: any = [];
      querySnapshot2.forEach((doc) => {
        fetchedData2.push({
          id: doc.id,
          ...doc.data()
        });
      });
      setRanks(fetchedData2);
    };

    fetchData2().catch(console.error);
  }, []);

  useEffect(() => {
    if (competitionId) {
      const unsub = onSnapshot(
        doc(db, "competitions", competitionId),
        (doc: any) => {
          setData({
            ...doc.data(),
            startDate: doc.data().startDate.toDate(),
            endDate: doc.data().endDate.toDate()
          });
          setIsFinished(doc.data().status === "finished");
          setLoading(false);
        }
      );

      getDocs(collection(db, "teams")).then((querySnapshot) => {
        const teamsArr: any[] = [];

        querySnapshot.forEach((doc: any) => {
          teamsArr.push({ ...doc.data(), id: doc.id });
        });

        setTeams(teamsArr);
      });

      return unsub;
    }
  }, [competitionId]);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    form
      .validateFields()
      .then((values) => {
        const archers = values.archers;

        if (competitionId) {
          archers.map(async (archer: string) => {
            const archerData = JSON.parse(archer);

            const currentRank = ranks.find(
              (item: { id: any }) => item.id === archerData.rankId
            );

            const currentRank2 = ranks.find(
              (item: { id: any }) => item.id === archerData.rate2
            );

            const currentTeam = teams.find(
              (item: { id: any }) => item.id === archerData.teamId
            );

            if (competitionId) {
              await setDoc(
                doc(
                  db,
                  "competitions",
                  competitionId,
                  "archers",
                  archerData.email
                ),
                {
                  ...archerData,
                  rank: currentRank,
                  rank2: currentRank2,
                  team: currentTeam,
                  hanaPoints: [],
                  hasPoints: [],
                  totalPoints: 0
                },
                { merge: true }
              );

              await setDoc(
                doc(
                  db,
                  "competitions",
                  competitionId,
                  "invoices",
                  archerData.ssn
                ),
                {
                  isPaid: true,
                  date: new Date(),
                  author: userData
                },
                { merge: true }
              );

              await setDoc(
                doc(db, "users", archerData.ssn, "competitions", competitionId),
                {
                  id: competitionId,
                  ...data
                },
                { merge: true }
              );
            }
          });
        }

        setVisible(false);
        setConfirmLoading(false);
        form.resetFields();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const onTeamSelect = (teamId: string) => {
    getDocs(query(collection(db, "users"), where("teamId", "==", teamId))).then(
      (querySnapshot) => {
        const archersArr: any[] = [];
        setTotalArcher(querySnapshot.size);
        querySnapshot.forEach((doc: any) => {
          const archerData = doc.data();
          const currentRank = ranks.find(
            (item: { id: any }) => item.id === archerData.rankId
          );

          archersArr.push({
            ...archerData,
            key: doc.id,
            rankName: currentRank?.name || "Цол сонгоогүй"
          });
        });

        setArchersOptions(archersArr);
      }
    );
  };

  const removeArcher = async (archer: ArcheryProp) => {
    if (competitionId) {
      await deleteDoc(
        doc(db, "competitions", competitionId, "archers", archer.email)
      );
    }
  };

  const onCompFinish = async () => {
    if (competitionId) {
      setIsFinishedLoading(true);
      try {
        await setDoc(
          doc(db, "competitions", competitionId),
          {
            status: "finished"
          },
          { merge: true }
        );

        notification["success"]({
          message: "Амжилттай",
          description: `Тэмцээн амжилттай дууслаа.`
        });
      } catch (e) {
        notification["error"]({
          message: "Амжилтгүй",
          description: `Алдаа гарлаа.`
        });
      }
      setIsFinishedLoading(false);
    }
  };

  return (
    <>
      {data ? (
        <PageTitle
          title={data?.name}
          description={dayjs(data?.date).format("YYYY-MM-DD")}
          loading={loading}
          right={
            <>
              {userData.role !== "referee" && !isFinished && (
                <Button
                  shape="round"
                  onClick={() => setVisible(true)}
                  icon={<PlusOutlined />}
                  size="large"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  Харваач нэмэх
                </Button>
              )}
              {userData.role === "admin" && !isFinished && (
                <Button
                  shape="round"
                  onClick={() => onCompFinish()}
                  size="large"
                  loading={isFinishedLoading}
                >
                  Тэмцээн дуусгах
                </Button>
              )}
            </>
          }
        />
      ) : (
        ""
      )}
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <p>Нийт харваачдын тоо: {competitors.length}</p>
                  <p>Зурхайн нийт тоо: {data?.zurhai}</p>
                  <p>
                    Улсын чанартай тэмцээн:{" "}
                    {data.isRank === true ? "Тийм" : "Үгүй"}
                  </p>
                  <Tabs defaultActiveKey="3">
                    {userData.role !== "referee" && (
                      <TabPane tab="Харваачид" key="1">
                        <Archers
                          competitors={competitors}
                          loading={loading}
                          ranks={ranks}
                          teams={teams}
                          removeArcher={removeArcher}
                        />
                      </TabPane>
                    )}
                    {userData.role === "admin" && (
                      <TabPane tab="Багаар" key="2">
                        <ByTeam
                          competitors={competitors}
                          ranks={ranks}
                          teams={teams}
                          loading={loading}
                          zurhaiTotal={data?.zurhai}
                        />
                      </TabPane>
                    )}
                    {
                      <TabPane tab="Самбар" key="3">
                        <Board
                          competition={data}
                          competitors={competitors}
                          ranks={ranks}
                          teams={teams}
                          referees={data?.referees}
                          loading={loading}
                          zurhaiTotal={data?.zurhai}
                        />
                      </TabPane>
                    }
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Харваач нэмэх"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Нэмэх"
        cancelText="Болих"
        width={720}
        confirmLoading={confirmLoading}
      >
        <Form {...formItemLayout} form={form} initialValues={{ gender: "all" }}>
          <Form.Item
            label="Баг"
            name="team"
            rules={[{ required: true, message: "Баг сонгоно уу!" }]}
          >
            <Select
              placeholder="Баг"
              allowClear
              onChange={onTeamSelect}
              // showSearch
            >
              {teams
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((team) => {
                  return (
                    <Option key={team.id} value={team.id}>
                      {team.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Хүйс"
            name="gender"
            rules={[{ required: true, message: "хүйс сонгоно уу!" }]}
          >
            <Select placeholder="Хүйс" allowClear onChange={setArcherGender}>
              <Option key={"all"} value={"all"}>
                 Бүгд
              </Option>
              <Option key={"male"} value={"male"}>
                Эрэгтэй
              </Option>
              <Option key={"female"} value={"female"}>
                Эмэгтэй
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            // label={`Харваачид (${totalArcher})`}
            label={`Харваачид`}
            name="archers"
            rules={[{ required: true, message: "Харваачид сонгоно уу!" }]}
          >
            <Select placeholder="Харваачид" allowClear mode="multiple">
              {archersOptions.map((archer) => {
                if (archerGender !== "all" && archer.gender !== archerGender) {
                  return;
                }
                return (
                  <Option
                    key={JSON.stringify(archer)}
                    value={JSON.stringify(archer)}
                  >
                    {archer.firstName} {archer.lastName} /{archer.rankName}/
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
export default Competition;
