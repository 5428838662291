import React, { useState, useEffect } from "react";
import { PageTitle } from "../../components";
import { Table, Space, Button, Select, Popconfirm } from "antd";
import { db } from "../../firebase";
import {
  collection,
  doc,
  onSnapshot,
  getDocs,
  query,
  where,
  updateDoc,
  deleteDoc
} from "firebase/firestore";
import type { ColumnsType, ColumnGroupType } from "antd/lib/table";
import dayjs from "dayjs";
import { PlusOutlined } from "@ant-design/icons";
import { Routes, Route, useParams } from "react-router-dom";
import { ArcheryProp } from "../../_helpers/type";
import { orderArchers, sumPoints } from "../../_helpers/utils";
import { useSelector } from "react-redux";
import "./table.scss";
const { Option } = Select;

interface DataType {
  key?: string;
  name?: string;
  date?: string;
  hanaPoints: number[];
  hasPoints: number[];
}

function Archers({ competitors, loading, ranks, teams, removeArcher }: any) {
  const userData = useSelector((state: any) => state.authentication.userData);

  const [searchKey, setSearchKey] = useState("displayName");
  const [searchValue, setSearchValue] = useState<string | null>(null);
  let { competitionId } = useParams();

  competitors = orderArchers(competitors);

  const ranksFilter: any[] = [];

  ranks.map((rank: any) => {
    ranksFilter.push({
      text: rank.name,
      value: rank.order
    });
  });

  const columns: ColumnsType<DataType> = [
    {
      width: 100,
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (text, row, index) => <>{index + 1}</>
    },
    {
      width: 100,
      title: "Оноо",
      dataIndex: "points",
      key: "points",
      render: (value, record) =>
        sumPoints(record?.hanaPoints) + sumPoints(record?.hasPoints) + " оноо",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        sumPoints(a?.hanaPoints) +
        sumPoints(a?.hasPoints) -
        (sumPoints(b?.hanaPoints) + sumPoints(b?.hasPoints))
    },
    {
      width: 200,
      title: "Овог",
      dataIndex: "lastName",
      key: "lastName"
    },
    {
      width: 200,
      title: "Нэр",
      dataIndex: "firstName",
      key: "firstName"
    },
    {
      width: 400,
      title: "Цол",
      dataIndex: "rankId",
      key: "rankId",
      render: (value, record: any) => {
        if (record.rank.order <= 9) {
          return record?.rank?.name;
        } else if (record.rank.order > record.rank2.order) {
          if (record.rank.order <= 20 && record.rank.order >= 12) {
            return record?.rank2?.name + ", " + record?.rank?.name;
          } else return record?.rank2?.name;
        } else return record?.rank?.name;
      },
      filters: ranksFilter,
      onFilter: (value: any, record: any) => record.rank.order === value,
      sortDirections: ["descend"]
    },
    {
      width: 400,
      title: "Баг",
      dataIndex: "teamId",
      key: "teamId",
      render: (value, record) => {
        const currentTeam = teams.find(
          (item: { id: any }) => item.id === value
        );

        return currentTeam ? (
          currentTeam.name
        ) : (
          <span style={{ color: "red" }}>Баг сонгоогүй</span>
        );
      }
    },
    // {
    //   width: 100,
    //   title: "Эхэн өрөг эхэн",
    //   dataIndex: "ehen_points",
    //   key: "ehen_points",
    //   render: (value, record) => sumPoints(record?.hanaPoints.slice(0, 4))
    // },
    // {
    //   width: 100,
    //   title: "Эхэн өрөг адаг",
    //   dataIndex: "ehen_dund_points",
    //   key: "ehen_dund_points",
    //   render: (value, record) => sumPoints(record?.hanaPoints.slice(4, 8))
    // },
    // {
    //   width: 100,
    //   title: "Дунд өрөг эхэн",
    //   dataIndex: "ehen_dund_points",
    //   key: "ehen_dund_points",
    //   render: (value, record) => sumPoints(record?.hanaPoints.slice(8, 12))
    // },
    // {
    //   width: 100,
    //   title: "Дунд өрөг адаг",
    //   dataIndex: "ehen_dund_points",
    //   key: "ehen_dund_points",
    //   render: (value, record) => sumPoints(record?.hanaPoints.slice(12, 16))
    // },
    // {
    //   width: 100,
    //   title: "Адаг өрөг",
    //   dataIndex: "ehen_dund_points",
    //   key: "ehen_dund_points",
    //   render: (value, record) => sumPoints(record?.hanaPoints.slice(16, 20))
    // },
    // {
    //   width: 100,
    //   title: "Хана нийт",
    //   dataIndex: "ehen_dund_points",
    //   key: "ehen_dund_points",
    //   render: (value, record) => sumPoints(record?.hanaPoints.slice(0, 20))
    // },
    // // {
    // //   width: 100,
    // //   title: "Эхэн өрөг эхэн",
    // //   dataIndex: "ehen_points",
    // //   key: "ehen_points",
    // //   render: (value, record) => record?.hasPoints
    // // },
    // {
    //   width: 100,
    //   title: "Эхэн өрөг эхэн",
    //   dataIndex: "ehen_points",
    //   key: "ehen_points",
    //   render: (value, record) => sumPoints(record?.hasPoints.slice(0, 4))
    // },
    // {
    //   width: 100,
    //   title: "Эхэн өрөг адаг",
    //   dataIndex: "ehen_dund_points",
    //   key: "ehen_dund_points",
    //   render: (value, record) => sumPoints(record?.hasPoints.slice(4, 8))
    // },
    // {
    //   width: 100,
    //   title: "Дунд өрөг эхэн",
    //   dataIndex: "ehen_dund_points",
    //   key: "ehen_dund_points",
    //   render: (value, record) => sumPoints(record?.hasPoints.slice(8, 12))
    // },
    // {
    //   width: 100,
    //   title: "Дунд өрөг адаг",
    //   dataIndex: "ehen_dund_points",
    //   key: "ehen_dund_points",
    //   render: (value, record) => sumPoints(record?.hasPoints.slice(12, 16))
    // },
    // {
    //   width: 100,
    //   title: "Адаг өрөг",
    //   dataIndex: "ehen_dund_points",
    //   key: "ehen_dund_points",
    //   render: (value, record) => sumPoints(record?.hasPoints.slice(16, 20))
    // },
    // {
    //   width: 100,
    //   title: "Хасаа нийт",
    //   dataIndex: "ehen_dund_points",
    //   key: "ehen_dund_points",
    //   render: (value, record) => sumPoints(record?.hasPoints.slice(0, 20))
    // },
    {
      title: "Хүйс",
      dataIndex: "gender",
      key: "gender",
      filters: [
        {
          text: "Эрэгтэй",
          value: "male"
        },
        {
          text: "Эмэгтэй",
          value: "female"
        }
      ],
      onFilter: (value: any, record: any) => record.gender === value,
      sortDirections: ["descend"],
      render: (value, record) => (value === "male" ? "Эрэгтэй" : "Эмэгтэй")
    },
    {
      title: "Үйлдэл",
      key: "action",
      render: (_, record: any) => (
        <>
          {userData.role === "admin" && (
            <Space size="middle">
              <Popconfirm
                title="Хасахдаа итгэлтэй байна уу?"
                onConfirm={() => removeArcher(record)}
                okText="Тийм"
                cancelText="Үгүй"
              >
                <Button type="link">Хасах</Button>
              </Popconfirm>
            </Space>
          )}
        </>
      )
    }
  ];

  const onFinish = () => {
    console.log("search");
  };

  return (
    <>
      {/* <div className="row">
        <div className="col-lg-8">
          <Form
            layout="inline"
            style={{ marginBottom: 10 }}
            initialValues={{ type: searchKey }}
          >
            <Form.Item name="type" label="Талбар">
              <Select
                value={searchKey}
                style={{ width: 300 }}
                onChange={(val) => setSearchKey(val)}
                allowClear
              >
                <Option value="displayName">Овогоор</Option>
              </Select>
            </Form.Item>
            <Form.Item name="name" label="Хайх утга">
              <Input onChange={(e) => setSearchValue(e.target.value)} />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" onClick={() => onFinish()}>
                Хайх
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div> */}
      <div className="table-responsive">
        <Table
          className="table-striped-rows"
          size="middle"
          columns={columns}
          dataSource={competitors}
          loading={loading}
          rowKey={(item): any => item.key}
          pagination={{ defaultPageSize: 16 }}
        ></Table>
      </div>
    </>
  );
}
export default Archers;
