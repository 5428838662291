import { useState, useRef } from "react";
import { PageTitle } from "../../components";
import { Button, Form, Input, message, Upload } from "antd";
import { db } from "../../firebase";
import { collection, doc, setDoc, addDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";

const storage = getStorage();

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

function AddNews() {
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const [value, setValue] = useState("");
  const [newsImage, setNewsImage] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const userData = useSelector((state: any) => state.authentication.userData);

  const editorRef = useRef(null);

  const onFinish = () => {
    // @ts-ignore
    const content =
      // @ts-ignore
      editorRef.current.getEditor().getLength() > 1
        ? // @ts-ignore
          editorRef.current.getEditor().getText().trim().length
        : 0;

    if (content <= 0) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
    }
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        addDoc(collection(db, "news"), {
          title: values.name,
          description: values.description,
          content: value,
          createdAt: new Date(),
          updatedAt: new Date(),
          author: userData,
        }).then(async (res) => {
          let imageUrl: string = "";
          let imageName: string = "";
          if (newsImage && newsImage !== null) {
            try {
              const originalExtension = newsImage.name.split(".").pop();
              const storageRef = ref(
                storage,
                `newsImage/${res.id}.${originalExtension}`
              );
              await uploadBytes(storageRef, newsImage);
              imageUrl = await getDownloadURL(storageRef);
              imageName = `${res.id}.${originalExtension}`;

              await setDoc(
                doc(db, "news", res.id),
                {
                  imageUrl,
                  imageName,
                },
                { merge: true }
              );
              console.log("Upload successful");
            } catch (error) {
              console.error("Error uploading image:", error);
            }
          }

          setLoading(false);
          form.resetFields();
          setNewsImage(null);
          setValue("");
          message.success("Мэдээ амжилттай нэмэгдлээ");
          navigate("/news");
        });
      })
      .catch((info) => {
        setLoading(false);
        console.log("Validate Failed:", info);
      });
  };

  const uploadButton = (
    <div>
      <div style={{ marginTop: 8 }}>Сонгох</div>
    </div>
  );

  const customRequest = ({ file, onSuccess, onError }: any) => {
    setNewsImage(file);
    onSuccess();
  };

  return (
    <>
      <PageTitle
        loading={loading}
        title={`Мэдээ нэмэх`}
        right={null}
        breadcrumbItems={[
          { link: "/news", title: "Мэдээ" },
          { title: "Мэдээ нэмэх" },
        ]}
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <Form
                    {...formItemLayout}
                    form={form}
                    onFinish={() => onFinish()}
                  >
                    <Form.Item
                      label="Нэр"
                      name="name"
                      rules={[{ required: true, message: "Нэр оруулна уу!" }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Товч"
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Товч мэдээлэл оруулна уу!",
                        },
                      ]}
                    >
                      <Input.TextArea />
                    </Form.Item>

                    <Form.Item label="Мэдээний зураг" valuePropName="newsImage">
                      <Upload
                        beforeUpload={(file) => {
                          const isPNG =
                            file.type === "image/png" ||
                            file.type === "image/jpg" ||
                            file.type === "image/jpeg";
                          if (!isPNG) {
                            message.error(`Зургийн файл буруу байна`);
                          }
                          return isPNG || Upload.LIST_IGNORE;
                        }}
                        customRequest={customRequest}
                        fileList={newsImage ? [newsImage] : []}
                        listType="picture-card"
                      >
                        {uploadButton}
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      label="Мэдээлэл"
                      style={{ height: 200, marginBottom: 100 }}
                    >
                      {/* <EditorToolbar /> */}
                      <div style={{ minHeight: 200 }}>
                        <ReactQuill
                          ref={editorRef}
                          theme="snow"
                          value={value}
                          onChange={setValue}
                          // modules={modules}
                          // formats={formats}
                          style={{
                            maxHeight: 150,
                          }}
                        />
                      </div>
                      {showError && (
                        <span style={{ color: "red" }}>
                          Мэдээлэл оруулна уу
                        </span>
                      )}
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                      >
                        Нийтлэх
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddNews;
