import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./_helpers";
import { ConfigProvider } from "antd";
import * as dayjs from "dayjs";
import * as isLeapYear from "dayjs/plugin/isLeapYear"; // import plugin
import "dayjs/locale/zh-cn"; // import locale
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";

import "./assets/css/bootstrap.min.css";
import "./assets/css/icons.min.css";
import "./App.scss";
// @ts-ignore
dayjs.extend(isLeapYear); // use plugin
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale("zh-cn"); // use locale

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ConfigProvider theme={{ token: { colorPrimary: "#3251d3" } }}>
            <App />
          </ConfigProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
