import React, { useEffect, useState, useRef, useCallback } from "react";
import "./board.scss";
import { Badge, Popconfirm, Space, Table, TableColumnsType } from "antd";
import { ColumnsType } from "antd/lib/table";
import { sumPoints } from "../../_helpers/utils";
import { useSelector } from "react-redux";
import { ArcheryProp } from "../../_helpers/type";

interface DataType {
  id?: string;
  teamName: number[];
  archers: ArcheryProp[];
}

const ByTeam = ({ competitors, zurhaiTotal }: any) => {
  const [teamData, setTeamData] = useState<any[]>([]);
  const teams = useSelector((state: any) => state.content.teams);

  useEffect(() => {
    let tmp: any[] = [];
    for (const team in teams) {
      const teamArchers = competitors.filter(
        (comp: ArcheryProp): boolean => comp.teamId === team
      );

      if (teamArchers.length > 0) {
        tmp.push({
          id: team,
          teamName: teams[team].name,
          archers: teamArchers
        });
      }
    }
    setTeamData(tmp);
  }, []);

  const columns: ColumnsType<DataType> = [
    {
      title: "Багийн нэр",
      dataIndex: "teamName",
      key: "teamName"
    },
    {
      title: "Харваачдын тоо",
      dataIndex: "archers",
      key: "archers",
      render: (text, row, index) => {
        return <>{row.archers.length}</>;
      }
    }
  ];

  const expandedRowRender = (record: any) => {
    const columns: TableColumnsType<ArcheryProp> = [
      {
        title: "Оноо",
        dataIndex: "points",
        key: "points",
        render: (value, record) =>
          sumPoints(record?.hanaPoints) + sumPoints(record?.hasPoints),
        defaultSortOrder: "descend",
        sorter: (a, b) =>
          sumPoints(a?.hanaPoints) +
          sumPoints(a?.hasPoints) -
          (sumPoints(b?.hanaPoints) + sumPoints(b?.hasPoints))
      },
      {
        title: "Овог",
        dataIndex: "lastName",
        key: "lastName"
      },
      {
        title: "Нэр",
        dataIndex: "firstName",
        key: "firstName"
      },
      {
        title: "Хүйс",
        dataIndex: "gender",
        key: "gender",
        render: (value, record) => (value === "male" ? "Эрэгтэй" : "Эмэгтэй")
      },
      {
        title: "Цол",
        dataIndex: "rankId",
        key: "rankId",
        render: (value, record: any) => {
          if (record.rank.order <= 9) {
            return record?.rank?.name;
          } else if (record.rank.order > record.rank2.order) {
            if (record.rank.order <= 20 && record.rank.order >= 12) {
              return record?.rank2?.name + ", " + record?.rank?.name;
            } else return record?.rank2?.name;
          } else return record?.rank?.name;
        }
      }
    ];

    return (
      <Table columns={columns} dataSource={record.archers} pagination={false} />
    );
  };

  return (
    <div className="team-container">
      <Table
        columns={columns}
        dataSource={teamData}
        expandable={{ expandedRowRender, defaultExpandedRowKeys: ["0"] }}
        rowKey={(item): any => item.id}
        pagination={{ defaultPageSize: 50 }}
      />
    </div>
  );
};

export default ByTeam;
