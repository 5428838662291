import HanaDavsan from "../assets/images/points/hana_davsan.png";
import HanaDutsan from "../assets/images/points/hana_dutsan.png";
import HanaDuursen from "../assets/images/points/hana_duursen.png";
import HanaHazgai from "../assets/images/points/hana_hazgai.png";
import HanaOnoson from "../assets/images/points/hana_onoson.png";
import HasaaDuursen from "../assets/images/points/hasaa_duursen.png";
import HasaaOnoson from "../assets/images/points/hasaa_onoson.png";

interface Pointmap {
  [key: number]: {
    key: String;
    label: String;
    point: number;
    line: any;
  };
}
export const points: Pointmap = {
  0: {
    key: "davsan",
    label: "Давсан",
    point: 0,
    line: HanaDavsan
  },
  1: {
    key: "onoson",
    label: "Оносон",
    point: 1,
    line: HanaOnoson
  },
  2: {
    key: "dutsan",
    label: "Дутсан",
    point: 0,
    line: HanaDutsan
  },
  3: {
    key: "hazgai",
    label: "Хазгай",
    point: 0,
    line: HanaHazgai
  },
  4: {
    key: "duursen",
    label: "Дүүрсэн",
    point: 0,
    line: HanaDuursen
  },
  5: {
    key: "onosonhas",
    label: "Оносон",
    point: 1,
    line: HasaaOnoson
  },
  6: {
    key: "duursenhas",
    label: "Дүүрсэн",
    point: 0,
    line: HasaaDuursen
  }
};
