import React, { useState, useEffect } from "react";
import { PageTitle } from "../../components";
import {
  Table,
  Space,
  Button,
  Form,
  Input,
  Select,
  Modal,
  Popconfirm,
  message,
  notification
} from "antd";
import {
  collection,
  doc,
  addDoc,
  onSnapshot,
  deleteDoc,
  getDocs,
  query,
  where
} from "firebase/firestore";
import { PlusOutlined } from "@ant-design/icons";
import { db } from "../../firebase";
import { Link, Outlet } from "react-router-dom";
import dayjs from "dayjs";
import type { ColumnsType } from "antd/lib/table";
import { API } from "../../_helpers";
import { useSelector } from "react-redux";

interface DataType {
  key: string;
  name: string;
  date: string;
}

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 }
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 18 }
};

const columns: ColumnsType<DataType> = [
  {
    title: "№",
    dataIndex: "id",
    key: "id",
    render: (text, row, index) => <>{index + 1}</>
  },
  {
    title: "Овог",
    dataIndex: "lastName",
    key: "lastName"
  },
  {
    title: "Нэр",
    dataIndex: "firstName",
    key: "firstName"
  },
  {
    title: "Имэйл хаяг",
    dataIndex: "email",
    key: "email"
  },
  {
    title: "Утасны дугаар",
    dataIndex: "phoneNumber",
    key: "phoneNumber"
  },
  {
    title: "Үйлдэл",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <Link to={`/leaders/${record.key}`} key={record.key}>
          Харах
        </Link>
        {/* <Popconfirm
          title="Устгахдаа итгэлтэй байна уу?"
          onConfirm={() => deleteReferee(record)}
          okText="Тийм"
          cancelText="Үгүй"
        >
          <Button type="link">Устгах</Button>
        </Popconfirm> */}
      </Space>
    )
  }
];

const deleteReferee = async (referee: any) => {
  // await deleteDoc(doc(db, "referees", referee.key));
  // message.success(
  //   `"${referee.lastName} ${referee.firstName}" шүүгч амжилттай устгалаа.`
  // );
};

const { Option } = Select;

function Leaders() {
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();
  const [data, setData] = useState<DataType[]>([]);
  const [oldData, setOldData] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchKey, setSearchKey] = useState("lastName");
  const [searchValue, setSearchValue] = useState(null);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const userData = useSelector((state: any) => state.authentication.userData);

  useEffect(() => {
    const unsub = onSnapshot(
      query(collection(db, "adminUsers"), where("role", "==", "leader")),
      (querySnapshot) => {
        const comps: DataType[] = [];

        querySnapshot.forEach((doc: any) => {
          comps.push({ ...doc.data(), key: doc.id });
        });
        setLoading(false);
        setData(comps);
        setOldData(comps);
      }
    );

    return unsub;
  }, []);

  const onFinish = () => {
    getDocs(
      query(
        collection(db, "adminUsers"),
        where(searchKey, ">=", searchValue),
        where("role", "==", "leader")
      )
    ).then((querySnapshot) => {
      const comps: DataType[] = [];

      querySnapshot.forEach((doc: any) => {
        comps.push({ ...doc.data(), key: doc.id });
      });
      setLoading(false);
      setData(comps);
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    form
      .validateFields()
      .then((values) => {
        // Bumka
        try {
          API.post("register-leader", {
            email: values.email,
            phoneNumber: "+976" + values.phoneNumber,
            password: values.phoneNumber,
            firstName: values.firstName,
            lastName: values.lastName
          }).then((result) => {
            if (result.status === 201) {
              setVisible(false);
              form.resetFields();
              setConfirmLoading(false);
              message.success(
                `"${values.lastName} ${values.firstName}" тэргүүлэгч амжилттай үүслээ.`
              );
            } else {
              setConfirmLoading(false);
              notification["error"]({
                message: "Амжилтгүй",
                description: result.data.error.toString()
              });
            }
          });
        } catch (e) {
          console.log(e);
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const clearSearchForm = () => {
    searchForm.resetFields();
    setSearchValue(null);
    setData(oldData);
  };

  return (
    <>
      <PageTitle
        title="Багийн ахлагчид"
        description="Багийн ахлагчдын жагсаалт"
        right={
          // userData.role === "leader" ? (
          //   <> </>
          // ) : (
          <Button
            shape="round"
            onClick={() => setVisible(true)}
            icon={<PlusOutlined />}
            size="large"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            Багийн ахлагч нэмэх
          </Button>
          // )
        }
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <Form
                        layout="inline"
                        style={{ marginBottom: 10 }}
                        form={searchForm}
                        initialValues={{ type: searchKey }}
                      >
                        <Form.Item name="type" label="Талбар">
                          <Select
                            value={searchKey}
                            style={{ width: 300 }}
                            onChange={(val) => setSearchKey(val)}
                            allowClear
                          >
                            <Option value="lastName">Овогоор</Option>
                            <Option value="firstName">Нэрээр</Option>
                            <Option value="email">Имэйл хаягаар</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="name"
                          label="Хайх утга"
                          rules={[
                            {
                              required: true,
                              message: "Хайх утга оруулна уу!"
                            }
                          ]}
                        >
                          <Input
                            onChange={(e: any) =>
                              setSearchValue(e.target.value)
                            }
                          />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button type="primary" onClick={() => onFinish()}>
                            Хайх
                          </Button>
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                          <Button
                            type="dashed"
                            onClick={() => clearSearchForm()}
                          >
                            Арилгах
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Table
                      columns={columns}
                      dataSource={data}
                      loading={loading}
                    />
                  </div>

                  {/* <Pagination
                    current={currentPage}
                    totalPages={totalPages}
                    prevAction={prevPage}
                    nextAction={nextPage}
                    changeAction={(num) => changePage(num)}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Багийн ахлагч нэмэх"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Нэмэх"
        cancelText="Болих"
        width={720}
        confirmLoading={confirmLoading}
      >
        <Form {...formItemLayout} form={form}>
          <Form.Item
            label="Имэйл хаяг"
            name="email"
            rules={[{ required: true, message: "Имэйл хаяг оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Овог"
            name="lastName"
            rules={[{ required: true, message: "Овог оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Нэр"
            name="firstName"
            rules={[{ required: true, message: "Нэр оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Утасны дугаар"
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Утасны дугаар оруулна уу!"
              }
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
export default Leaders;
