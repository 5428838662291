import React, { useState, useEffect } from "react";
import { PageTitle } from "../../components";
import {
  Table,
  Space,
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  message,
  Upload
} from "antd";
import { db } from "../../firebase";
import {
  collection,
  addDoc,
  onSnapshot,
  deleteDoc,
  doc
} from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import type { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";

interface DataType {
  key: string;
  name: string;
}

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 }
};

const columns: ColumnsType<DataType> = [
  {
    title: "№",
    dataIndex: "id",
    key: "id",
    render: (text, row, index) => <>{index + 1}</>
  },
  {
    title: "Нэр",
    dataIndex: "name",
    key: "name",
    render: (value, record: any) => <Space size="middle">{value}</Space>
  },
  {
    title: "Дугаар",
    dataIndex: "order",
    key: "order",
    render: (value, record: any) => <Space size="middle">{value}</Space>,
    defaultSortOrder: "ascend",
    sorter: (a: any, b: any) => a.order - b.order
  },
  {
    title: "Min point",
    dataIndex: "minPoint",
    key: "minPoint",
    render: (value, record: any) => <Space size="middle">{value}</Space>
  },
  {
    title: "Max point",
    dataIndex: "maxPoint",
    key: "maxPoint",
    render: (value, record: any) => <Space size="middle">{value}</Space>
  },
  {
    title: "Үйлдэл",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <Link to={`/ranks/${record.key}`} key={record.key}>
          Засах
        </Link>
        <Popconfirm
          title="Устгахдаа итгэлтэй байна уу?"
          onConfirm={() => deleteTeam(record)}
          okText="Тийм"
          cancelText="Үгүй"
        >
          <Button type="link">Устгах</Button>
        </Popconfirm>
      </Space>
    )
  }
];

const deleteTeam = async (team: any) => {
  await deleteDoc(doc(db, "ranks", team.key));
  message.success(`"${team.name}" цол амжилттай устгалаа.`);
};

function Ranks() {
  const [form] = Form.useForm();

  const [data, setData] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.authentication.userData);

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "ranks"), (querySnapshot) => {
      const teams: DataType[] = [];

      querySnapshot.forEach((doc: any) => {
        teams.push({ ...doc.data(), key: doc.id });
      });
      setLoading(false);
      setData(teams);
    });

    return unsub;
  }, []);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    form
      .validateFields()
      .then((values) => {
        addDoc(collection(db, "ranks"), {
          ...values,
          createdAt: new Date()
        }).then((response) => {
          form.resetFields();
          setConfirmLoading(false);
          setVisible(false);
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <PageTitle
        title="Цол"
        description="Цолын жагсаалт"
        right={
          userData.role === "leader" ? (
            <> </>
          ) : (
            <Button
              shape="round"
              onClick={() => setVisible(true)}
              icon={<PlusOutlined />}
              size="large"
            >
              Цол нэмэх
            </Button>
          )
        }
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      columns={columns}
                      dataSource={data}
                      loading={loading}
                      rowKey={(item) => item.key}
                      pagination={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Цол нэмэх"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Нэмэх"
        cancelText="Болих"
        width={720}
        confirmLoading={confirmLoading}
      >
        <Form {...formItemLayout} form={form}>
          <Form.Item
            label="Нэр"
            name="name"
            rules={[{ required: true, message: "Нэр оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Дугаар"
            name="order"
            rules={[{ required: true, message: "Дугаар оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Min point"
            name="minPoint"
            rules={[{ required: true, message: "Min point оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Max point"
            name="maxPoint"
            rules={[{ required: true, message: "Max point оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
export default Ranks;
