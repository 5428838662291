import React, { useState, useEffect } from "react";
import { PageTitle } from "../../components";
import {
  Table,
  Space,
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  message,
  Upload
} from "antd";
import { db } from "../../firebase";
import {
  collection,
  addDoc,
  onSnapshot,
  deleteDoc,
  doc
} from "firebase/firestore";
import { useDispatch } from "react-redux";
import type { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { firebaseUpload } from "../../_actions/upload.actions";
import { useSelector } from "react-redux";

interface DataType {
  key: string;
  name: string;
}

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 }
};

const columns: ColumnsType<DataType> = [
  {
    title: "№",
    dataIndex: "id",
    key: "id",
    render: (text, row, index) => <>{index + 1}</>
  },
  {
    title: "Нэр",
    dataIndex: "name",
    key: "name",
    render: (value, record: any) => (
      <Space size="middle">
        <Link to={`/teams/${record.key}`} key={record.key}>
          {record?.logo && (
            <img src={record?.logo} alt="logo" style={{ width: 50 }} />
          )}
          {value}
        </Link>
      </Space>
    )
  }
  // {
  //   title: "Үйлдэл",
  //   key: "action",
  //   render: (_, record) => (
  //     <Space size="middle">
  //       <Link to={`/teams/${record.key}`} key={record.key}>
  //         Засах
  //       </Link>
  //       <Popconfirm
  //         title="Устгахдаа итгэлтэй байна уу?"
  //         onConfirm={() => deleteTeam(record)}
  //         okText="Тийм"
  //         cancelText="Үгүй"
  //       >
  //         <Button type="link">Устгах</Button>
  //       </Popconfirm>
  //     </Space>
  //   ),
  // },
];

const deleteTeam = async (team: any) => {
  await deleteDoc(doc(db, "teams", team.key));
  message.success(`"${team.name}" баг амжилттай устгалаа.`);
};

function Teams() {
  const [form] = Form.useForm();

  const [data, setData] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const logoUrl = useSelector(
    (state: any) => state.firebaseUploderReducer.fileUrl
  );
  const logoLoading = useSelector(
    (state: any) => state.firebaseUploderReducer.onProgress
  );
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.authentication.userData);

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "teams"), (querySnapshot) => {
      const teams: DataType[] = [];

      querySnapshot.forEach((doc: any) => {
        teams.push({ ...doc.data(), key: doc.id });
      });
      teams.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
      setLoading(false);
      setData(teams);
    });

    return unsub;
  }, []);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    form
      .validateFields()
      .then((values) => {
        addDoc(collection(db, "teams"), {
          name: values.name,
          createdAt: new Date()
          // logo: logoUrl,
        }).then((response) => {
          form.resetFields();
          setConfirmLoading(false);
          setVisible(false);
          dispatch({ type: "FIREBASE_UPLOAD_CLEAR" });
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const firebaseUploadHandler = (e: any) => {
    // @ts-ignore
    // TODO : Firebase file name ijilhen ued replace hiigeed baigaag zasah
    dispatch(firebaseUpload({ ...e, path: "logo" }));
  };

  const uploadButton = (
    <div>
      {logoLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <PageTitle
        title="Багууд"
        description="Багийн жагсаалт"
        right={
          // userData.role === "leader" ? (
          //   <> </>
          // ) : (
          <Button
            shape="round"
            onClick={() => setVisible(true)}
            icon={<PlusOutlined />}
            size="large"
          >
            Баг нэмэх
          </Button>
          // )
        }
      />
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      columns={columns}
                      dataSource={data}
                      loading={loading}
                      rowKey={(item) => item.key}
                      pagination={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Баг нэмэх"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Нэмэх"
        cancelText="Болих"
        width={720}
        confirmLoading={confirmLoading}
      >
        <Form {...formItemLayout} form={form}>
          <Form.Item
            label="Нэр"
            name="name"
            rules={[{ required: true, message: "Нэр оруулна уу!" }]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item label="Upload" valuePropName="logo">
            {logoUrl ? (
              <div
                style={{
                  width: 150,
                  border: 1,
                  borderColor: "#495057",
                  borderStyle: "solid",
                }}
              >
                <img src={logoUrl} alt="logo" style={{ width: 150 }} />
              </div>
            ) : (
              <Upload
                customRequest={(e: any) => firebaseUploadHandler(e)}
                listType="picture-card"
              >
                {uploadButton}
              </Upload>
            )}
          </Form.Item> */}
        </Form>
      </Modal>
    </>
  );
}
export default Teams;
